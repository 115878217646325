// Simulates infinite scrolling on desktop for categories and search after the first click on the "See more" button.
// The listenForButtonClick() function checks if the required DOM elements are present. If so, it adds or removes a "scrollEventlistener" event.
// This event is also enabled and disabled at the beginning and end of the ajax request to avoid too many clicks during product loading. See => generateNewBlocProd in functions.js.
function listenForButtonClick() {
    const pagerNav = document.querySelector('.pagerNav.end_pagi');
    const button = pagerNav?.querySelector('.button.primary');

    if (button) {
        button.addEventListener('click', () => {
            seeAllBtnClick();
        });
    }
}

// The isInViewport function is used to check if the button is visible on the screen. If it is, it returns true and the click is simulated; otherwise, it does not. This function is called in the scroll click condition.
function isInViewport(element) {
    var elementTop = $(element).offset().top;
    var elementBottom = elementTop + $(element).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
}

// Function that enables or disables the scroll listener based on whether the first click is made and the progress of the ajax request. See => generateNewBlocProd.
function seeAllBtnClick(action = null) {
    if (action == "add") {
        window.addEventListener('scroll', handleScroll);
        window.hasScrollListener = true;
    } else if (action == "remove") {
        window.removeEventListener('scroll', handleScroll);
        window.hasScrollListener = false;
    } else {
        if (window.hasScrollListener) {
            window.removeEventListener('scroll', handleScroll);
            window.hasScrollListener = false;
        } else {
            window.addEventListener('scroll', handleScroll);
            window.hasScrollListener = true;
        }
    }
}

// Second function that listens to the scroll position relative to the total height of the page. The condition requires the isInViewport function to return true.
  function handleScroll() {
    const maxItems = document.getElementById('totalElems').value;
    const productLoaded = $('#list_item .item:not(".push")').length;
    const pagerNav = document.querySelector('.pagerNav.end_pagi');
    const button = pagerNav?.querySelector('.button.primary');

    // Opacity is modified instead of display because isInViewport() needs the element to be in the viewport, not just in the DOM, to work. The pagination bar will only appear if all items have been loaded on the page.
    button.style.opacity = '1';

    if (isInViewport(button)) {
        button.click();
    }
}

listenForButtonClick();

// Function responsible for simulating the click and hiding the button when returning from a product page IF the 'p_id' cookie is present AND the value of initial_page is greater than 1.
$(function() {
    if (getCookie('p_id') && $('#initial_page').val() > 1) {
        seeAllBtnClick();
    }
});