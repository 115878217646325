/*
    JS file structure to follow:
    - Global variables
    - Initialization
    - Events
    - Callback functions
    - General functions

    IMPORTANT! : Initializations and events are to be placed in the $(function() { ... });
    IMPORTANT! : Callback functions and general functions are to be placed outside of the $(function() { ... });

    Don't forget to comment the code in english for better understanding and maintainability of the code. And if possible use explicit variables and function names.
*/

var rex_php_tags = new RegExp("<\\?php(?:[\\n\\r]|.)+?\\?>(?!\"|'')", "gim");
var rex_script_tags = new RegExp("<script(?:[\\n\\r]|.)+?\\/script>", "gim");
var rex_comments_tags = new RegExp("<!--(?:[\\n\\r]|.)+?-->", "gim");

// Initialization
$(function () {
    // cgv.php : Change table tarif transport position under Article IV - livraison
    moveTablePos();

    // cookies.php : Allow cookies lightbox to be reopened after being closed once
    $(".cookieShowLightbox").on('click tap', function () {
        cookies_bloc_lightbox.css('display', 'block');
    });

    // login : unhide data protection aside
    $('.login_protection_donnees_msg').css('display', 'block');

    // nos_engagements.html : Smooth scroll to anchor
    if ($("body.nos_engagements").length) {
        $('a[href*="#"]').click(function () {
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top - 120
                    }, 800);
                    return false;
                }
            }
        });
    }

    // order_detail.php : Check if return is valid
    $(".popup_numero_retour select").change(function () {
        var selectedElement = $(this);
        checkValidityRequestOfReturn.call(selectedElement);
    });

    // tg_module.php : Home module slider initialization
    initializeHomeGondoleSwiper();

    // Replace Un Rien C'est Tout (URCT) donation product URL (empty) with basket URL
    // If adding more products, do the same for each product ID in $arrayProductDonation in bloc_dons.php
    $("[data-i='2087']").find($('a')).attr("href", create_link('order_basket'));
    $("[data-product-id='2087']").find($('a')).attr("href", create_link('order_basket'));

    // Tunnel V3 delivery choice: execute chooseTransporteur function on page load in case default selected delivery mode is relay or store
    if ($('.active .choix_delivery').length > 0 && $('#delivery_address_recap_other').css('display') === 'none') {
        $('.active .choix_delivery').chooseTransporteur();
    }

    /*
    Load only if the page has the class '.cart'
    */
    if ($('body').hasClass('cart')) {
        toggleModifySize();
    }

    $(window).on("removeBasketItemCustom", function (data) {
        toggleModifySize();
    });

    //Handle preheader closing
    $('body').on('click touchstart', '.close_promo', function () {
        $('body').removeClass('hasBando pheader');
        $('.promo_bar').remove();
    });

    if ($('[data-payment-mode="paypal_worldline"]').length) {
        $('[data-payment-mode="paypal_worldline"]').on('click', function () {
            $( ".paypal .bank_subtitle" ).addClass( "loader_paypal" );
            $('.paypal_worldline').click();
        });
    }

    if ($('[data-payment-mode="bancontact_worldline"]').length) {
        $('[data-payment-mode="bancontact_worldline"]').on('click', function () {
            $( ".bancontact .bank_subtitle" ).addClass( "loader_bancontact" );
            $('.bancontact_worldline').click();
        });
    }

    //Enable burger menu on desktop/tablet when screen is too narrow to fit the entire header menu (when header is sticky)    
    $( window ).on("resize scroll", checkStickyMenu);

    setTimeout(() => {
        // Display shad on hover on menu's entries
        enableMenuShad();
        checkStickyMenu();
    }, 500);
    
    //Hide menu dropdowns if they (only have 1 entry AND its text is identical to the main one) OR (have 0 entries AND 0 subcategories)
    $('.categ_container').each(function () {
        var catName = $(this).siblings('.categ_name').text();
        var entryName = $(this).find('.object_name').text();

        if ((($(this).find('.object_name').length == 1) && (catName == entryName))  || (($(this).find('.object_name').length == 0) && ($(this).find('.subcat_name').length == 0))) {
            $(this).hide();
        }
    });

    // Init product swipers
    initItemSlider();

    if ($('#lightbox_achat_express').length) {
        window.setTimeout(eventSizeButton, 1000);
    }
});

/*
TG module slider initialization
*/
function initializeHomeGondoleSwiper() {
    $('.homeGondoleSwiper').each(function () {
        var homeTgSwiper = new Swiper(this, {
            slidesPerView: 3,
            spaceBetween: 20,
            roundLengths: true,
            breakpoints: {
                1300: {
                    slidesPerView: 4,
                    spaceBetween: 19,
                }
            }
        });
    });
}

// Strips php contents /!\ THIS DELETES PHP TAGS AND EVERYTHING IN BETWEEN /!\
function stripPhpContents(str) {

    return str.replace(rex_php_tags, '');
}

// Strips js contents /!\ THIS DELETES SCRIPT TAGS AND EVERYTHING IN BETWEEN /!\
function stripJSContents(str) {

    return str.replace(rex_script_tags, '');
}

// Strips html comments /!\ THIS DELETES COMMENTS TAGS AND EVERYTHING IN BETWEEN /!\
function stripHTMLComments(str) {

    return str.replace(rex_comments_tags, '');
}

function stripCombo(str) {

    var output = stripHTMLComments(str);

    output = stripJSContents(output);
    output = stripPhpContents(output);

    return output;
}

/*
Close search field
**/
function closeSearch() {
    $(".wrap_search_field").fadeOut();
    $("body").removeClass("search");
    $('#site_head_wrap').removeClass("search_open");
    $("#trigger_recherche").css("opacity", "1");
    $("#shad_menu").removeClass("actif for_search");
    if ($(window).scrollTop() <= 0) {
        $("#site_head_wrap").removeClass("sticky");
    }
}

/*
Shows button's underlying loader and triggers defined action
**/
var elmt;
var loader;
var prev_display;

function waitingProcess(loader, action, id_form) {

    var elmt = $(this),
        loader = $(loader);

    prev_display = elmt.css('display');

    if (!loader.parent().hasClass('loading')) {

        loader.parent().addClass("loading");

        switch (action) {

            case 0:
                window.setTimeout(ajax_checkAllForm, 1000, tabAuthTop, 1, path_relative_root);
                break;

            case 1:
                window.setTimeout(ajax_checkAllForm, 1000, tabLostPwdTop, 1, path_relative_root);
                break;

            case 2:
                window.setTimeout(ajaxDeconnect, 1000, path_relative_root);
                break;

            case 3:
                window.setTimeout(function () {
                    checkAllForm(tabError, 0, path_relative_root, id_form);
                    loader.parent().removeClass('loading');
                }, 1000); // be careful not to modify the time 1000 so that the intelinput has time to fill in the data correctly
                break;

            case 4:
                window.setTimeout(ajax_checkAllForm, 1000, tabAuth, 1, path_relative_root);
                break;

            case 5:
                window.setTimeout(ajax_checkAllForm, 1000, tabLostPwd, 1, path_relative_root);
                break;

            case 6:
                window.setTimeout(checkFormParrainage, 1000);
                break;

            case 7:
                if (checkAllForm(tabErrorGrattez, 0, path_relative_root, id_form)) {

                    window.setTimeout(processGrattezGagnez(), 1000);
                }
                break;
        }
    }
}

/*
Hides button's underlying loader
**/
function hideLoader() {
    if (loader) {
        loader.css('display', 'none');
        loader.removeAttr("style").parent().removeClass("loading");
    }
    if (elmt) { elmt.css('display', prev_display); }
}

/************************** AJAX FUNCTIONS **************************/
/*******************************************************************/
/*
Ajax to disconnect
**/
function ajaxDeconnect(path_relative_root) {

    var provenance = window.location.pathname,
        cpt_client = new RegExp(/(?:adresses|mes_infos|newsletter|parrainage|mon-compte|facture-liste|avoir-liste|commande_detail|panier|livraison-kiala|livraison|paiement|banque|erreur_cmd|erreur_commande|banque_refus|remerciements|endereco|newsletter-pt|conta|conta-lista|haver-lista|encomenda_detalhes|cesta|remessa|pagamento|banco|erreur_cmd_pt|erros-encomenda|banco-recusa|agradecimento)/),
        response;

    // Get the current page's name
    provenance = provenance.substr(provenance.lastIndexOf('/'), provenance.lastIndexOf('.'));

    // This actually disconnects the client
    response = ajax_file(path_relative_root + 'ajax_deconnect.php');

    // If there was an error
    if (!response)
        alert('Erreur lors de la déconnexion.');

    // Else if the current page can't be displayed when not logged in: redirect
    else if (cpt_client.test(provenance))
        window.location.href = path_relative_root + create_link('connexion_login');

    // Else: refresh
    else window.location.reload();

    // If the page hasn't been reloaded: remove the loader and return false
    hideLoader();

    return false;
}

/*
create the instantiation of the XHR object that the browser imports
**/
function getXMLHttpRequest() {

    var xhr = null;

    if (window.XMLHttpRequest || window.ActiveXObject) {
        if (window.ActiveXObject) {
            var tableau_xhr_activeX = [
                'Msxml2.XMLHTTP.6.0',
                'Msxml2.XMLHTTP.3.0',
                'Msxml2.XMLHTTP',
                'Microsoft.XMLHTTP'
            ];

            for (var value in tableau_xhr_activeX) {
                try {
                    xhr = new ActiveXObject(tableau_xhr_activeX[value]);
                    break;
                } catch (error) { };
            };

        } else {
            xhr = new XMLHttpRequest();
        };

    } else {
        return null;
    };
    return xhr;
};

/*
Allows non-refreshing of a page by using the XHR object
**/
function ajax_file(fichier) {
    if (window.XMLHttpRequest) // FIREFOX-
        xhr_object = new XMLHttpRequest();
    else if (window.ActiveXObject) // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");
    else
        return (false);
    xhr_object.open("GET", fichier, false);
    xhr_object.send(null);
    if (xhr_object.readyState == 4) return (xhr_object.responseText);
    else return (false);
}

function ajax_file_post(url, params) {
    if (window.XMLHttpRequest) // FIREFOX-
        xhr_object = new XMLHttpRequest();
    else if (window.ActiveXObject) // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");

    xhr_object.open("POST", url, false);
    xhr_object.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhr_object.send(params);

    if (xhr_object.readyState == 4 && xhr_object.status == 200) {
        return xhr_object.responseText;
    }
}

function ajax_file_XML(fichier) {
    if (window.XMLHttpRequest) // FIREFOX-
        xhr_object = new XMLHttpRequest();
    else if (window.ActiveXObject) // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");
    else
        return (false);
    xhr_object.open("GET", fichier, false);
    xhr_object.send(null);
    if (xhr_object.readyState == 4) return (xhr_object.responseXML);
    else return (false);
}

/************************** RAYON **********************************/
/*******************************************************************/
/*
Called upon color change in product page
**/
function loadProduct(new_id, type, page, itm_rr_id) {
    var form = this;
    var old_id = this.getValue('produit_id');
    var col_id = this.getValue(this.getValue('idCouleur'));
    var sze_id = this.getValue(this.getValue('idTaille'));
    var act = this.getValue('act');
    var qte_itm = 1;
    if (this.elements.namedItem('qteProd')) {
        var qte_itm = this.elements.namedItem('qteProd').value;
    }

    var is_model = this.getValue('hdn_is_model');
    var is_wishlist = $('#wishlist').length > 0;
    if ($('#tunnel_right_col').length > 0) {
        is_wishlist = false;
    }
    var is_achat_express = $('#is_achat_express_v2').val() == 1;
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var cpt_prod = itm_rr_id !== '' ? itm_rr_id.replace('_', '') : '';
    let isCategSuper = this.getValue('isCategSuper') ? this.getValue('isCategSuper') : 0;

    var data = {
        produit_id: old_id,
        produit_principal: old_id,
        couleurProd: col_id,
        tailleProd: sze_id,
        act: act,
        qteProd: qte_itm,
        hdn_product_id: old_id,
        hdn_is_model: is_model,
        produit_id_new: new_id,
        type: type,
        type_page: (page !== undefined && page !== '') ? page : 'product',
        is_achat_express: is_wishlist ? 1 : 0,
        is_achat_express_v2: is_achat_express && !is_wishlist ? 1 : 0,
        cpt_prod: cpt_prod - 1,
        isCategSuper: isCategSuper
    };

    if (is_wishlist) {
        data.wishlist = true;
    }

    $.ajax({
        type: 'post',
        url: path_relative_root + 'ajax_get_product_regroup_ref.php',
        data: data,
        success: function (res) {

            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error('parseJSON');
                return;
            }

            if (res.success == 'ok') {
                var datas = res.datas;
                if (data.type_page == 'product') {
                    var $html_main = $(stripCombo(datas.html_main));
                    var main_wrapper = $('.product_main_wrapper', $html_main);
                    var zoom_wrapper = $('.zoom_main_wrapper', $html_main);
                    var feats_wrapper = $('.product_info_wrapper', $html_main);

                    if (data.is_achat_express_v2) {
                        // Photo and information
                        var itm_id = old_id + (itm_rr_id !== '' ? itm_rr_id.replace('_', '-') : '');
                        $('#itm-' + itm_id).replaceWith($html_main);

                        var new_itm_id = new_id + (itm_rr_id !== '' ? itm_rr_id.replace('_', '-') : '');
                        var margin_right = $('#itm-' + new_itm_id).next().css('margin-right');

                        if ($(".homepage").length > 0 || $(".satellite_store_details").length > 0) {
                            save_description = $('#itm-' + new_itm_id + ' .wrapper_description').html();
                            $('#itm-' + new_itm_id + ' .wrapper_description').html(save_description);
                            save_item_title = $('#itm-' + new_itm_id + ' .item_title').html();
                            $('#itm-' + new_itm_id + ' .item_title').html('<span>' + save_item_title + '</span>');
                            $('#itm-' + new_itm_id + ' .item_price').insertBefore('#itm-' + new_itm_id + '.wrap_rolloverproduit');

                            if ($(".satellite_store_details").length > 0) {
                                $('#itm-' + new_itm_id).addClass('swiper-slide');
                                $('#itm-' + new_itm_id).css('margin-right', margin_right);
                            }
                        } else if ($(".product_page").length > 0 || $(".rayon_lookbook").length > 0) {
                            if ($(".product_page").length > 0) {
                                $('#itm-' + new_itm_id + ' .imgWrapper').prepend($('#itm-' + new_itm_id + ' .item_price'));
                                $('#itm-' + new_itm_id).addClass('assoc');
                                $('#itm-' + new_itm_id).css('margin-right', margin_right);
                            } else if ($(".rayon_lookbook").length > 0) {
                                $('#itm-' + new_itm_id + ' .imgWrapper').addClass('imgWrapperinlook');
                            }
                        }

                        $('input.color_' + new_id + itm_rr_id).prop('checked', false);
                        $('input#color_' + new_id + '_' + data.couleurProd + itm_rr_id).prop('checked', true);
                    } else if (is_wishlist) {
                        // Photo and information
                        $('.product_main_wrapper', '#lightbox_achat_express').html(main_wrapper.html());
                        // Description composition, delivery, size guide
                        $('.product_info_wrapper', '#lightbox_achat_express').html(feats_wrapper.html());
                    } else {
                        var color = $("#ligne_couleur").html();
                        var cms_page_wrapper = $('.product_cms_bloc', $html_main);
                        var assoc_rayon_wrapper = $('.assoc_rayon', $html_main);

                        // Photo and information
                        $('.product_main_wrapper', '#site_global_wrap').html(main_wrapper.html());

                        // Zoombox
                        $('.zoom_main_wrapper', '#site_global_wrap').html(zoom_wrapper.html());

                        // Description composition, delivery, size guide
                        $('.product_info_wrapper', '#site_global_wrap').html(feats_wrapper.html());

                        if (!$('#lightbox_achat_express').is(':visible')) {
                            // Cms page module
                            $('.product_cms_bloc', '#site_global_wrap').html(cms_page_wrapper.html());

                            // Association rayon
                            $('.assoc_rayon', '#site_global_wrap').html(assoc_rayon_wrapper.html());
                        }

                        $("#ligne_couleur").html(color);
                        $('input.color').prop('checked', false);
                        $('input#color_' + data.couleurProd).prop('checked', true);

                        $("#ctaAvailabilityInStoreTitle").html(Translator.translate('availabilityInStore'));


                        // On scroll en haut de la page dans le cas d'un regroupement
                        $('html, body').animate({
                            scrollTop: 0
                        }, 500);
                    }

                    if ($("#buyNowSwiper").length > 0) {
                        var buyNowSwiper = document.getElementById("buyNowSwiper");

                        if (buyNowSwiper.getElementsByClassName("swiper-slide").length) {
                            buyNowSwiper = new Swiper("#buyNowSwiper", {
                                sliderPerView: 1,
                                spaceBetween: 50,
                                nextButton: '.swiper-button-next',
                                prevButton: '.swiper-button-prev'
                            });
                        }
                    }

                    $('.share_wrapper').appendTo('#itm_name');

                    $('.sizesList label').on("click", function () {
                        $('.choices_list').removeClass('opened');
                    });
                }

                // Lookbook lightbox preview: add "Go to product card" button
                if ($('#lightbox_achat_express').length) {
                    let productUrl = 'p/' + datas.product.titreobjet.toLowerCase() + '-' + datas.product.id + '.html';
                    addGoToProductCardButton(productUrl);
                }

                json_colisages = res.stock;
                // Define the sizes that still have stock
                if (!data.is_achat_express_v2) {
                    setAvailableSizes.call($('[name="itm_color"]:checked', $(form)));
                }

                if (wShop.$refs.wAlertestockForm) {
                    wShop.$refs.wAlertestockForm.reloadBloc += 1;
                }

            } else {
                $('#btn_error').fadeIn();
            }

            // By default show 'sélectionner une taille' btn if no size is chosen
            noSizeSelected();

            // FP Description Accordion
            fpAccordion();

            // If only one image on page FP
            if ($('.top_page_produit .mainSlideFp .swiper-slide').length === 1) {
                if (!$('body').hasClass('lookbook')) {
                    $('.top_page_produit').addClass('onlyOneImage');
                }
            } else {
                $('.top_page_produit').removeClass('onlyOneImage');
            }

            // Get already selected size and apply it when color change
            const getSavedState = sessionStorage.getItem('radioId');
            if (getSavedState !== null && getSavedState !== '') {
                const radioButton = $('#' + getSavedState);
                if (radioButton.length) {
                    radioButton.click();
                }
            }
        },
        complete: function () {

            var heads = $("[data-tab-head]");
            var containers = $("[data-tab-container]");

            if (window.YT_Player !== undefined) {
                window.YT_Player.destroy();
                window.YT_Player = undefined;
                window.YT_vid_id = undefined;
            }

            // initialize items swipers
            initItemSlider();

            var activateTab = function (event) {
                event.preventDefault();

                var element = $(this);
                var tab_number = element.attr("data-tab");
                var head_container = element.parent();
                var container_name, container, scrollpane;

                head_container.addClass("current").siblings().removeClass("current");
                container_name = head_container.parent().attr("data-tab-head");
                container = containers.filter("[data-tab-container='" + container_name + "']");
                scrollpane = container.find("[data-tab]").hide().filter("[data-tab='" + tab_number + "']").show().find('.scroll-pane');

                if (scrollpane.length > 0) {
                    scrollpane.jScrollPane();
                }
            };

            setTimeout(function () {
                var productImgsWrapper = $(".productImgsWrapper");
                var productDescrWrapper = $(".productDescrWrapper");

                $(document).on("scroll", function () {
                    var scrollTop = $(document).scrollTop();
                    var newTop = (scrollTop - (productImgsWrapper.innerHeight() - productDescrWrapper.innerHeight()));

                    if (scrollTop >= productImgsWrapper.innerHeight() - productDescrWrapper.innerHeight()) {
                        productDescrWrapper.css("top", "-" + newTop + "px");
                    } else {
                        productDescrWrapper.css("top", "auto");
                    }
                });

                setTimeout(function () {
                    checkSubmitState();
                    eventButton();
                }, 300);
            }, 250);

            heads.each(function () {
                var head = $(this);
                var children = head.children();

                children.removeClass("current").filter(":first").addClass("current");
                children.find('[data-tab]').click(activateTab);
            });

            $("[data-tab-container]").children('div').eq(Math.abs(1 - $('.current', "[data-tab-head]").index())).css('display', 'none');

            var assocs_elm = $('#assocs_slider', '#site_global_wrap').get(0);
            var ranges_elm = $('#ranges_slider', '#site_global_wrap').get(0);

            if ((data.type_page == 'product' && !data.is_achat_express_v2)) {
                getAvailableQuantity.call($('form.product_info', '#site_global_wrap').get(0), data.produit_id_new, data.qteProd, '', data.couleurProd, itm_rr_id, is_wishlist);
            }
            $('#addToWishlistButton', '#site_global_wrap').on('click', addToWishlist);

            if (!data.is_achat_express_v2) {
                checkProductInWishlist();
            }

            if (is_wishlist) {
                // event sur le bouton d'ajout à la wishlist
                $("#addToWishlistButton").on("click", addToWishlist);
            }

            // Update Zoombox
            $('.mainSlideFp .swiper-slide').each(function (index) {
                var newContent = $(this).html();
                newContent = newContent.replace(/(src="[^"]*?)prodfp/g, '$1large');
                $('#zoom_box_wrapper .swiper-slide').eq(index).html(newContent);
            });
        }
    });
}

/* Called upon color change in product page */
function onItemColorChange(form, is_regroup, itm_rr_id) {
    var control, prod_id, color_name;
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';

    if (this.checked) {

        var input_color = $(form).find('[name="idCouleur"]').val();
        control = $(form).find('#' + input_color)[0];
        control.value = this.value.trim();
        color_name = $(this).data("nom");

        var input_id = $(form).find('[name="itm_id_' + control.value + itm_rr_id + '"]').val();
        prod_id = input_id.trim();

        $(".chossen_color").html(color_name);

        if (is_regroup) {
            loadProduct.call(form, prod_id, 'couleur', '', itm_rr_id);

        } else {
            // Check if the product is already in the wishlist
            var in_wishlist = checkProductInWishlist();
            var img = $(this).data('pic');

            changeBtnAddWishlist(in_wishlist);

            // Define the sizes that still have stock
            setAvailableSizes.call(this);
        }
    }
}

// By default show 'sélectionner une taille' btn if no size is chosen 
function noSizeSelected() {
    if ($('#ligne_pointure .form_itm.check.size input[type="radio"]:checked').length > 0) {
        // At least one radio button is checked
        $('#btn_add_cart').val(Translator.translate('add_basket'));
        $('#btn_add_cart').removeClass('noSize');
        $('#btn_add_cart').css('opacity', '1');

    } else {
        // No radio buttons are checked
        $('#btn_add_cart').val(Translator.translate('select_size'));
        $('#btn_add_cart').addClass('noSize');
    }
}

/*
Called upon size change in product page
**/
function onItemSizeChange(form, event, itm_rr_id) {
    $('.validEmailTxt').remove();

    var control;
    var is_wishlist = $('#wishlist').length > 0;

    if ($('#tunnel_right_col').length > 0) {
        is_wishlist = false;
    }

    var is_achat_express_v2 = ($('#is_achat_express_v2').val() == 1 && !is_wishlist);
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';

    if (this.checked) {
        if (window.dataLayer !== undefined) {
            window.dataLayer.push({
                'event': 'changeSize',
                'produit': {
                    'size_value': ($(this).attr('data-nom')),
                }
            });

            if (window.dataLayer[0].product) {
                window.dataLayer[0].product.size = ($(this).attr('data-nom'));
            }
        }

        if ($('#sizeerror').is(':visible')) {
            $('#sizeerror').slideUp();
        }

        var id = form.getValue('produit_id');
        var elem = is_achat_express_v2 ? '_' + id + itm_rr_id : '';

        control = form.elements.namedItem('tailleProd');
        control.value = this.value.trim();

        // Depending on the color, we check if the product is available
        getAvailableQuantity($('#produit_principal' + elem).val(), $('#qteProd' + elem).val(), 'product', event, itm_rr_id, is_wishlist, this);

        // Check if the product is already in the wishlist
        if (!is_achat_express_v2) {
            checkProductInWishlist();
        }
    }

    // By default show 'sélectionner une taille' btn if no size is chosen
    noSizeSelected();

    // Reset btn -> M'avertir de sa disponibilité when we change size
    if ($(this).parent().hasClass('disabled')) {
        $('#bloc_add_alert, #btn_alert_stock').show();
        $('#bloc_add_alert_form, .bloc_add_alert_confirmation, .bloc_add_alert_erreur').hide();

    } else {
        $('#bloc_add_alert, #btn_alert_stock').hide();
    }

    // if input size is selected get id
    $("#ligne_pointure input[type='radio']").each(function () {
        if ($(this).is(':checked')) {
            const radioId = $(this).attr("id");
            sessionStorage.setItem('radioId', radioId);
            return false;
        }
    });
}

/**
 * Retrieves the stock of sizes by color and their quantity
 * Author: Timothy Beard / Aurélien Renault
 * Since: 2017-09-27
 */
function setAvailableSizes() {
    var produit_id = $('#produit_principal').val();
    var data = { produit_id: new Array(produit_id) };

    if (
        (typeof GEM_Services != 'undefined' && typeof GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings != 'undefined' && typeof GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE != 'undefined' && GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE !== false && enable_gbe_sfs == false) ||
        (typeof GEP != 'undefined' && typeof GEP.isOperatedByGlobale != 'undefined' && GEP.isOperatedByGlobale !== false && enable_gbe_sfs == false)
    ) {
        data.warehouse_only = 1;
    }

    var elem_id = $(this).val();

    $.ajax({
        url: path_relative_root + 'ajax_get_colisages_states_by_color_size.php',
        type: 'post',
        data: data,
        success: function (res) {
            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error("parseJSON");

                return;
            }

            if (res.success && res.result && res.result[produit_id] != undefined && res.result[produit_id][elem_id] != undefined) {
                var sizes_tab = res.result[produit_id][elem_id];
                var size_box, is_disabled;
                var has_stock = res.result[produit_id]['stock_quantite'] > 0;

                for (size_id in sizes_tab) {
                    if (sizes_tab.hasOwnProperty(size_id)) {
                        size_box = $('[name="itm_size"]').filter('[value="' + size_id + '"]');
                        is_disabled = !sizes_tab[size_id];
                        size_box.siblings();

                        if (!is_disabled) {
                            size_box.removeClass('disabled');
                            $('#label_' + size_id).removeClass('disabled');
                        } else {
                            size_box.addClass('disabled');
                            $('#label_' + size_id).addClass('disabled');
                        }
                    }
                }

                if (has_stock) {
                    $("#bloc_add_basket").show();
                    $("#bloc_add_alert").hide();
                } else {
                    $("#bloc_add_basket").hide();
                    $("#bloc_add_alert").show();
                }
            }
        }
    });
}

/*
Refreshes available quantity
**/
function getAvailableQuantity(id, qty, from, event, itm_rr_id, is_wishlist) {
    itm_rr_id = (itm_rr_id !== undefined) ? itm_rr_id : '';
    var is_achat_express = $('#is_achat_express_v2').val() == 1 && !is_wishlist;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';
    var form = (this instanceof HTMLFormElement) ? this : document.querySelector('#prod_info_' + id + itm_rr_id);
    var color_id = $('#couleurProd' + elem).val();
    var size_id = $('#tailleProd' + elem).val();
    var plnb = $('#produit_lot_nb' + elem).val();
    // If we don't have a size or color already selected then we don't do any verification
    if (color_id == '' || size_id == '') {
        return;
    }

    var data = {
        idprod: $('#produit_id' + elem).val(),
        idcouleur: color_id,
        idtaille: size_id,
        json: '1'
    };

    //Promocode: specific offered product
    if (form && form instanceof HTMLFormElement && form.getValue('is_lightbox_specifique') === 'true') {
        data.idprod = form.getValue('produit_id');
        data.idcouleur = form.getValue('couleurProd');
        data.idtaille = form.getValue('tailleProd');
    }

    if (from == 'basket') {
        data.suff = $('#produit_id' + elem).val();
    }

    if (plnb) {
        data.produit_lot_nb = plnb;
    }

    $.ajax({
        url: path_relative_root + 'ajax_liste_qte.php',
        type: 'get',
        async: false, // Should be async but would then need a loader so...
        dataType: 'json',
        data: data,
        success: function (res) {

            if (res.stock_tampon == 0) {
                /**
                 * Flag alert stock
                 * If enable, bloc_add_alert exist in DOM, hide add basket and display alert stock
                 * If disabled, bloc_add_alert don't exist in DOM, display disabled add basket
                 */
                if ($(".bloc_add_alert" + elem).length > 0) {
                    $(".bloc_add_alert" + elem).show();
                    if (!is_achat_express) {
                        $("#bloc_add_basket").hide();
                    }
                } else {

                    if (is_achat_express) {
                        $("#bloc_size_not_available" + elem).show();
                    } else {
                        disableAddCart();
                        $("#size_is_not_available" + elem).fadeIn(100);
                        $('#bloc_add_basket').addClass('disabled');
                        $("#bloc_add_alert" + elem).show();
                        $("#bloc_add_alert").show();
                        $("#btn_add_cart").addClass('disabled');
                    }
                }

                if (document.querySelector("#prod_info" + elem + " .rollover_left")) {
                    document.querySelector("#prod_info" + elem + " .rollover_left").style.display = "none";
                }
                $("#qteProd").val(0);
                $('.taunt_text').hide();

            } else {
                // If product has stock
                if (res.stock_tampon > 0 && res.stock_tampon <= 3) {
                    setTimeout(() => {
                        $('.taunt_text').fadeIn(100);
                    }, 100);
                } else {
                    $('.taunt_text').fadeOut(100);
                }

                if (is_achat_express) {
                    var from = '';

                    if ($('#in_basket').val() == 1) {
                        from = 'basket';
                    }

                    waitingAddToBasket(event, form, from, itm_rr_id);
                } else {
                    $("#size_is_not_available").fadeOut(100);
                    enableAddCart();
                    $("#btn_add_cart").show();
                    $("#bloc_add_alert").hide();
                    $("#bloc_add_basket").removeClass('disabled');
                    $("#qteProd").val(1);
                }
            }
        }
    });
}

/*
In the product information sheet, display an email field so that we can be notified when the product is available.
**/
function displayAlertStock() {
    if (!$('#btn_add_alert').is(':visible')) {
        $('#btn_add_alert').show();
    }

    var $taille_id = $("#tailleProd").val(),
        $couleur_id = $("#couleurProd").val();

    if ($taille_id == '') {
        if (!$('#sizeerror').is(':visible')) {
            $('#sizeerror').slideDown();
        }
        return;
    } else if ($couleur_id == '') {
        alert(Translator.translate('js_error_couleur'));
        return;
    }

    var isFormVisible = $('.bloc_add_alert_form').is(':visible');
    var isMessageVisible = $('.bloc_add_alert_confirmation').is(':visible');
    var isErrorVisible = $('.bloc_add_alert_erreur').is(':visible');

    if (isFormVisible || isMessageVisible || isErrorVisible) {
        if (isFormVisible) {
            $('.bloc_add_alert_form').hide();
        }

        if (isMessageVisible) {
            $('.bloc_add_alert_confirmation').hide();
        }

        if (isErrorVisible) {
            $('.bloc_add_alert_erreur').hide();
        }
    } else {
        $('#bloc_add_alert_form').show();
        $('#btn_alert_stock').hide();
    }
}

/*
Close stock alert by product
*/
function closeSizeNotAvailable(elem) {
    // Hide bloc bloc_size_not_available_
    $("#bloc_size_not_available_" + elem).hide();

    // Show bloc colors and size
    $("#prod_info_" + elem + " .rollover_left").show();
}

/*
Update basket
*/
function newUpdatePanier(idProduit, i, paniervirtuelId, prixU, path_web, titreObjet, idElTaille, lastTaille, idElCouleur, lastCouleur) {
    if (i != '') {
        i = '_' + i;
        if (!$('body').hasClass('product_page')) {
            prixU = $('#prixU' + i).val();
        } else {
            prixU = $('#prixU').val();
        }
    }

    var couleurs = $('#couleurProd' + i);

    if ($('#produit_id').val() == idProduit || Object.keys(couleurs).length === 0) {
        couleurs = $('#couleurProd');
    }

    var tailles = $('#tailleProd' + i);
    if (Object.keys(tailles).length === 0 || tailles.val() == '') {
        tailles = $('#tailleProd');
    }
    var $alertbox = $('#alertbox1');
    var res = '';
    var html_err = '';
    var flagError = false;

    html_err = '<div class="txt_alert"></div>';
    html_err += '<div class="wrapper_btn"><a onclick="closeMultiShad(\'alertbox1\');" href="#" class="button primary">' + Translator.translate('OK') + '</a></div>';

    if ($alertbox.length == 0) {

        $alertbox = $('<div id="alertbox1" class="lightbox">' + html_err + '</div>');
        $('body').append($alertbox.hide());
    }

    if (tailles.length && tailles.val() == '') {

        res = Translator.translate('js_error_size');
        flagError = true;
    } else if (couleurs && couleurs.val() == '') {

        res = Translator.translate('js_error_couleur');
        flagError = true;
    } else if (document.getElementById('qteProd' + i) && document.getElementById('qteProd' + i).value == '') {

        res = Translator.translate('js_error_quantity');
        flagError = true;
    }

    if (flagError) {

        $alertbox.find('.txt_alert').html(res);
        openMultiShad('alertbox1', 'fixed');

        return false;
    } else {
        var qte = 0;
        var quantity = $('#qteProd' + i);
        if (Object.keys(quantity).length === 0) {
            quantity = $('#qteProd');
        }
        if (typeof quantity !== "undefined") {
            var qte = quantity.val();
        }
        var idCouleur = 0;
        var idTaille = 0;

        if (couleurs && couleurs.val() != 'none')
            idCouleur = couleurs.val();
        ;
        if (tailles && tailles.val() != 'none')
            idTaille = tailles.val();

        var array_response = [];
        var response = ajax_file(path_web + 'ajax_upd_panier.php?prixu=' + prixU + '&idprod=' + idProduit + '&idcouleur=' + idCouleur + '&idtaille=' + idTaille + '&qte=' + qte + '&panierId=' + paniervirtuelId);

        if (response) {

            if (response.indexOf("//") <= 0) {
                try {

                    array_response = JSON.parse(response);
                } catch (e) {

                    console.error('JSON ERROR: could not parse data');

                    return false;
                }
            } else {

                array_response = response.split("//");
                var designation = array_response[0];

                if (titreObjet.match('^[0-9]')) {

                    if (designation == 1) {

                        designation += ' lot de';
                    } else {

                        designation += ' lots de';
                    }
                }

                designation += ' ' + titreObjet;

                if (document.getElementById(idElTaille)) {

                    if (document.getElementById(idElTaille).selectedIndex) {

                        designation += ' - Taille ' + document.getElementById(idElTaille).options[document.getElementById(idElTaille).selectedIndex].text;
                    } else if (lastTaille != '') {

                        designation += ' - Taille ' + lastTaille;
                    }
                }

                if ($('[name="' + idElCouleur + '"]').length > 0) {

                    if ($('[name="' + idElCouleur + '"]:checked').length > 0) {

                        designation += ' - ' + $('[name="' + idElCouleur + '"]:checked').get(0).nextElementSibling.title.trim();
                    } else if (lastCouleur != '') {

                        designation += ' - ' + lastCouleur;
                    }
                }

                array_response[0] = designation;
            }
        }

        return array_response;
    }
}

/*
Close stock alert
**/
function closeAlerteStock(elem) {
    // Hide bloc alert stock
    $('.bloc_add_alert_' + elem).hide();
    $("#prod_info_" + elem + " .rollover_left").show();

    // Reset mail input
    $('.bloc_add_alert_' + elem + ' #mail_alerte_stock_' + elem).val("");
    $('.bloc_add_alert_' + elem + ' .alert_stock').show();
    $('.bloc_add_alert_' + elem + ' .bloc_add_alert_form').show();
    $('.bloc_add_alert_' + elem + ' .bloc_add_alert_confirmation').hide();
}

// Close bloc_add_alert_confirmation -> confirmation msg
function closePopUp() {
    $('.bloc_add_alert_confirmation, #bloc_add_alert_form, #bloc_add_alert').hide();

    // Reset btn -> M'avertir de sa disponibilité when we close pop up
    if ($('.form_itm.check.size').hasClass('disabled')) {
        $('#bloc_add_alert, #btn_alert_stock').show();
        $('#bloc_add_alert_form, .bloc_add_alert_confirmation, .bloc_add_alert_erreur').hide();

    } else {
        $('#bloc_add_alert, #btn_alert_stock').hide();
    }
}
/*
Enables the sending of an email that confirms the registration of their email, to alert the person when the product will be available.
**/
function sendMailAlertStock(type_alert, form, itm_rr_id) {
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var id = form.getValue('produit_id');
    var is_achat_express = $('#is_achat_express_v2').val() !== undefined;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';

    if ($(form).hasClass('product_info')) {
        var $email = $("#mail_alerte_stock" + elem).val(),
            $produit_id = $("#produit_id").val(),
            $taille_id = $("#tailleProd").val(),
            $couleur_id = $("#couleurProd").val(),
            $type_alert = type_alert;
    } else {
        var $email = $('#mail_alerte_stock' + elem).val(),
            $produit_id = $('#produit_principal' + elem).val(),
            $taille_id = $("#tailleProd" + elem).val(),
            $couleur_id = $("#couleurProd" + elem).val(),
            $type_alert = type_alert;
    }

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var mail_value = $('#mail_alerte_stock' + elem).val();
    $verif_mail = mail_value.match(mailformat);

    if ($email == '' || !$verif_mail) {
        $('#mail_alerte_stock' + elem).addClass('inputErr');
        if(!$('.validEmailTxt').length > 0) {
            $('<span class="validEmailTxt">' + Translator.translate("renseigner_email") + '</span>').insertAfter($('#mail_alerte_stock' + elem).closest('#bloc_add_alert'));
        }

    } else {
        $('#mail_alerte_stock' + elem).removeClass('inputErr');
        $('.validEmailTxt').remove();

        $.ajax({
            type: 'post',
            url: path_relative_root + create_link('ajax_mail_alert_stock'),
            data: {
                email: $email,
                produit_id: $produit_id,
                taille_id: $taille_id,
                couleur_id: $couleur_id,
                type_alert: $type_alert
            },
            success: function (response) {
                // Check If FP Alert Stock (other Alert stock with Achat express will still work)
                if ($(form).hasClass('product_info')) {
                    $('.bloc_add_alert_form').hide();
                    $('.bloc_add_alert_erreur').hide();
                    $('.bloc_add_alert_confirmation').show();

                } else {
                    $('.bloc_add_alert' + elem + ' .alert_stock').hide();
                    $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').hide();
                    $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').hide();
                    $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').show();
                    $('.bloc_add_alert' + elem + ' .title_disponibilite').hide();
                }
            },
            fail: function () {
                if ($(form).hasClass('product_info')) {
                    $('.bloc_add_alert_confirmation').hide();
                    $('.bloc_add_alert_erreur').show();

                } else {
                    $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').hide();
                    $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').show();
                    $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').show();
                }
            }
        });
    }
}

/*
Creates a cookie
**/
function createCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

/*
Reads a cookie
**/
function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) == 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }
    return null;
}

/*
Erases a cookie
**/
function eraseCookie(name) {
    createCookie(name, "", -1);
}

/*
Checks product parameters before calling the addToBasket function (add to basket processing)
**/
function waitingAddToBasket(evt, form, from, itm_rr_id) {
    var id = '';
    var produit_id = $(form).find('[name="produit_id"]').val();
    var suff = $(form).find('[name="suff"]').val();
    var panier_id = $(form).find('[name="panier_id"]').val();
    var titreObjet = $(form).find('[name="titreObjet"]').val();
    var idTaille = $(form).find('[name="idTaille"]').val();
    var lastTaille = $(form).find('[name="lastTaille"]').val();
    var idCouleur = $(form).find('[name="idCouleur"]').val();
    var lastCouleur = $(form).find('[name="lastCouleur"]').val();
    var toEval = $(form).find('[name="toEval"]').val();
    var page_name = $(form).find('[name="page_name"]').val();
    var path_web = $(form).find('[name="path_web"]').val();
    var enable_omniture = $(form).find('[name="enable_omniture"]').val() == 'true';
    var is_not_produit = $(form).find('[name="is_not_produit"]').val() == 'true';
    var is_lightbox_specifique = $(form).find('[name="is_lightbox_specifique"]').val();
    var is_achat_express = $('#is_achat_express_v2').val() == 1;
    var couleurProd = $(form).find('[name="couleurProd"]').val();
    var tailleProd = $(form).find('[name="tailleProd"]').val();
    var loader;


    if (!is_achat_express) {
        evt.preventDefault();
    }

    if (from === undefined) {

        from = '';
    }

    if (is_not_produit == true) {

        id += '_' + produit_id;
    }

    if (this.nextElementSibling && this.nextElementSibling.classList.contains('loader')) {
        loader = $(this.nextElementSibling);
        loader.addClass("loading");
    } else {
        $("#bloc_btn_active" + id).attr('class', 'cache');
        $("#bloc_btn_loader" + id).attr('class', 'f_right');
    }

    if (is_lightbox_specifique == 'true') {
        add_produit_specifique();
    } else {
        window.setTimeout(addToBasket, 1000, produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id, couleurProd, tailleProd);
    }
}

/*
Add product to basket
**/
function addToBasket(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id, couleurProd, tailleProd) {
    var id = '';
    // Formatting of field identifiers
    var prix_id = 'prixU_' + produit_id + (itm_rr_id ? itm_rr_id : '');
    var couleur_id = 'idCouleur_' + produit_id + (itm_rr_id ? itm_rr_id : '');
    var taille_id = 'idTaille_' + produit_id + (itm_rr_id ? itm_rr_id : '');

    if (from == 'basket' || is_not_produit == true || is_achat_express) {
        id += '_' + produit_id + itm_rr_id;
        prix_id = 'prixU' + id;

        if (is_not_produit == true) {
            var code_color = $('#couleurUnique' + id).text();
            var nom_color = $('#couleurUnique' + id).text();
        }
    }

    var prixU = is_not_produit ? $(prix_id).val() : $('#prixU').val();
    var idCouleurValue = is_not_produit ? $(couleur_id).val() : couleur_id;
    var idTailleValue = is_not_produit ? $(taille_id).val() : taille_id;

    var array_panier = newUpdatePanier(produit_id, suff, panier_id, prixU, path_web, titreObjet, idTailleValue, lastTaille, idCouleurValue, lastCouleur, couleurProd, tailleProd);

    if (array_panier != false) {
        if (from == 'basket') {
            location.reload();
            return false;
        }

        var type = '';

        if (array_panier[1] == 'false' || array_panier.length == 0) {
            type = 'err';
        }

        var omnitureEvent = '';

        if (array_panier[1] == 1) {
            omnitureEvent = 'scOpen,scAdd'; // the first product added to the cart
        } else {
            omnitureEvent = 'scAdd'; // there is already a product in the cart
        }

        if (loader) {
            loader.parent().removeClass('loading');
        } else {
            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }

        if (array_panier[1] > 0 && enable_omniture == true) {
            void (s.t());
            s.pageName = "'" + page_name + "'";
            s.products = ";" + produit_id;
            s.events = omnitureEvent;
            void (s.t());
        }

        // DATALAYER V2
        if (array_panier.length >= 0 && array_panier[5] !== undefined) {
            var dl_objet = array_panier[5];

            if (is_achat_express) {
                var new_dl_objet = JSON.parse(dl_objet);
                new_dl_objet.ecommerce.is_achat_express = true;
                dl_objet = JSON.stringify(new_dl_objet);
            }

            pushIntoDatalayer(dl_objet);
        }

        // Update top cart
        var response_basket = ajax_file(path_web + 'ajax_show_basket.php');

        $("#cart_top").html(response_basket);

        var count_bask = $('#cart_top .cart').attr('data-cart');

        $("#lightbox_achat_express, .lightbox_achat_express, #shad").hide();

        newshowmodal(produit_id, is_not_produit);
    } else {
        if (loader) {
            loader.parent().removeClass('loading');
        } else {
            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }
    }

    eval(toEval);

    if (array_panier != false) {
        if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1) {
            var svn = document.getElementsByTagName("SELECT");

            for (var a = 0; a < svn.length; a++) {
                svn[a].style.visibility = "hidden";
            }
        }
    }
    $('.loader').removeClass('loading');
    return false;
}

/*
Set product parameters
**/
function pushIntoDatalayer(data) {
    if (typeof (data) == 'object') {
        window.dataLayer.push(data);
    } else if (typeof (data) == 'string') {
        window.dataLayer.push(JSON.parse(data));
    }
}

/**
 * Start add to cart
 * @param products
 * @param type
 * @param root
 * @param id_produit
 * @param is_not_produit
 */
function newshowmodal(products, type, root, id_produit, is_not_produit) {
    topCartAppear();
    window.setTimeout(function () {

        topCartDesappear();
    }, 5000);
}

/*
On cart roll over disable scroll
**/
function bodyScrollLock() {
    "use strict";

    $('body').css({
        'overflow': 'hidden',
        'padding-right': '17px'
    });
    $('#site_head_wrap').css({
        'padding-right': '17px'
    });
}

/*
On cart roll out enable scroll
**/
function bodyScrollUnlock() {
    "use strict";

    $('body').css({
        'overflow': 'inherit',
        'padding-right': '0'
    });
    $('#site_head_wrap').css({
        'padding-right': '0'
    });
}

/*
Displays the list of quantities for a product (HTML select tag)
**/
function showQteListe(elementID, idProduit, parentPath, suff) {
    var couleurs_id = "couleurProd";
    var tailles_id = "tailleProd";

    if (elementID != "liste_qte") {
        couleurs_id += "_" + idProduit;
        tailles_id += "_" + idProduit;
    }

    var listQte = $("#" + elementID);
    var alternative_listQte = $("#liste_qte_spe");

    if (listQte.length || alternative_listQte.length) {
        if (listQte.length) {
            listQte.html(
                '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;margin-top:-4px;"><tr><td style="font-size:11px;color:#dedede" align="right"><img src="' +
                parentPath +
                'img/sablier_2.gif" style="margin-bottom:-6px;margin-right:10px;" />Chargement...</td></tr></table>'
            );

            var couleurs = $("#" + couleurs_id).length
                ? $("#" + couleurs_id).val()
                : "none";
            var tailles = $("#" + tailles_id).length
                ? $("#" + tailles_id).val()
                : "none";
        } else {
            alternative_listQte.html(listQte.html());

            var couleurs = "none";
            var tailles = "none";
        }

        var response = ajax_file(
            parentPath + create_link('ajax_liste_quantity') + "?idprod=" + idProduit + "&idcouleur=" + couleurs + "&idtaille=" + tailles + "&produit_lot_nb=" + $("#produit_lot_nb").val() + "&suff=" + (suff || "")
        );

        if (response) {
            if (listQte.length) {
                listQte.html(response);
            } else {
                alternative_listQte.html(response);
            }

            var select_stock = $("#qteProd");
            var regexp_epuise = /Stock /;
            var txt_stock = $("#qteProd>option:selected").text();

            if (
                select_stock.length > 0 &&
                txt_stock.match(regexp_epuise) != null
            ) {
                $("#add_alert").show();
                $("#add_basket").hide();
            } else {
                $("#add_alert").hide();
                $("#add_basket").show();
            }
        }
    }
}

/*
Remove product from basket
**/
function ajax_RemoveBasketProd(basket_id, prod_id, qte) {

    $.ajax({
        type: 'post',
        data: {
            basket_id: basket_id,
            prod_id: prod_id,
            qte: qte
        },
        url: path_relative_root + 'ajax_removeBasketProd.php',
        success: function (res) {

            try {

                res = $.parseJSON(res);
            } catch (e) {

                console.error('parseJSON');

                return;
            }

            $('#cart_top').html(res.datas.html);
            $('#show_top_cart').addClass('hover');
            $('#cart_top').on('mouseenter touchstart', function (evt) {
            });
        }
    });
}

/*
Hover menu handler
**/
function hoverMenu(element, action) {
    $(element).children(".connexion_top").toggleClass("hover", action);
}

function toggleShadMenuClass(action) {
    var shadMenu = $("#shad_menu");
    if (!shadMenu.hasClass("for_search")) {
        shadMenu.toggleClass("actif", action);
    }
}

/*
Show the top cart
**/
function topCartAppear() {
    var show_top_cart_parent = $("#show_top_cart");
    var shad_menu = $("#shad_menu");

    /* Closing look lightbox if displayed */
    if ($(".lookLightbox.actif").length) {
        $(".lookLightbox").removeClass("actif");
    }

    if ($("body.cart").length) {
        show_top_cart_parent.addClass("hover");
        if (!shad_menu.hasClass("for_menu")) {
            shad_menu.addClass("actif");
        }
    } else {
        show_top_cart_parent.addClass("hover");
        if (!shad_menu.hasClass("for_menu")) {
            shad_menu.addClass("actif");
        }
    }
}

/*
Hide the top cart
**/
function topCartDesappear() {
    var show_top_cart_parent = $("#show_top_cart");
    var shad_menu = $("#shad_menu");

    if ($("body.cart").length) {
        show_top_cart_parent.removeClass("hover");
        $(".smallbasketExpress").removeClass("actif");

        if (!shad_menu.hasClass("for_search")) {
            shad_menu.removeClass("actif");
        }
    } else {
        show_top_cart_parent.removeClass("hover");
        $(".smallbasketExpress").removeClass("actif");

        if (!shad_menu.hasClass("for_search")) {
            shad_menu.removeClass("actif");
        }
    }
}

$("#cart_top").on("mouseenter", function () {
    topCartAppear();
});

$("#cart_top").on("mouseleave", function () {
    topCartDesappear();
});

/*
Open shade
**/
function openMultiShad(id) {

    // Add sidebox behaviour to lightboxes where the class cannot be added manually (app)
    if (id == 'selection-offer') {
        $("#" + id).addClass('sidebox');
    }
    if (id == 'delivery_home_choice') {
        $("#" + id).addClass('sidebox large');
    }

    // Show modbox and make sure closing routine won't fire more than once
    if ($("#" + id).hasClass('sidebox')) {
        $("#" + id).css('display', 'block').animate({ right: 0 }, 400);
    } else {
        $("#" + id).fadeIn(200);
    }

    if (id !== 'zoomBox') {
        $("#shad").addClass('actif').css("display", "block").fadeTo("fast", '0.8').on("click", function () {
            closeMultiShad(id);
        });
    }

    if (id === "zoomBox") {
        $('#zoom_box_wrapper').css('opacity', '1').addClass('open');
        $("#shad").addClass('actif').fadeTo("fast", 0.8).on("click", function () {
            closeMultiShad('zoomBox');
        });
    }

    if ($("#buyNowSwiper").length > 0) {

        window.setTimeout(function () {

            buyNowSwiper = new Swiper("#buyNowSwiper", {

                width: 260,
                height: 390,
                sliderPerView: 1,
                navigation: {
                    nextEl: '.modboxNext',
                    prevEl: '.modboxPrev',
                },
            });
        }, 50);
    }
}

/*
Close shade
**/
function closeMultiShad(id) {

    $('#shad').off('click');

    // Hide modbox and shade and destroy shade's click event

    if ($("#" + id).hasClass('sidebox')) {
        var sideboxWidth = $("#" + id).hasClass('large') ? '-600px' : '-300px';

        $("#" + id).animate({ right: sideboxWidth }, 400).queue(function () {

            if ($('#shad').is(":visible")) {
                $('#shad').fadeTo("fast", 0, function () {
                    $('#shad').css('display', 'none');
                });
            }

            if ($('#shad_abox').is(":visible")) {
                $('#shad_abox').fadeTo("fast", 0, function () {
                    $('#shad_abox').css('display', 'none');
                });
            }

            $(this).css('display', 'none').dequeue();
        });
    } else {
        $("#" + id).fadeOut(200).queue(function () {

            if ($('#shad').is(":visible")) {
                $('#shad').fadeTo("fast", 0, function () {
                    $('#shad').css('display', 'none');
                });

                if($('#shad').hasClass('actif')) {
                    $('#shad').removeClass('actif');
                }
            }

            if ($('#shad_abox').is(":visible")) {
                $('#shad_abox').fadeTo("fast", 0, function () {
                    $('#shad_abox').css('display', 'none');
                });
            }

            $(this).dequeue();
        });
    }

    if (id === 'zoomBox') {
        $('#zoom_box_wrapper').css('opacity', '0').removeClass('open');
        $("#shad").removeClass('actif');
    }
}

/*
Change site language
**/
function changeLocale(locale, uri, site) {
    $.ajax({
        url: path_relative_root + 'ajax_get_uri_by_lang.php',
        type: 'post',
        data: { lang: locale, url: uri, site: site },
        success: function (data) {
            if (data != '') {

                var force = '';
                if (uri == '/sunco-paris.html') {
                    force = '?force';
                }

                location.href = data + force;
            }
        }
    });

}

/*
Custom Select Menu
**/
function initCustomSelect(select) {
    var x, i, j, selElmnt, a, b, c;
    if (select === undefined) {
        select = 'custom-select';
    }
    x = document.getElementsByClassName(select);
    // Si jamais l'element est déja customisé alors on n'y touche pas
    if ($('.' + select + ' .select-items').length > 0) {
        return;
    }
    for (i = 0; i < x.length; i++) {
        selElmnt = x[i].getElementsByTagName("select")[0];
        a = document.createElement("DIV");
        a.setAttribute("class", "select-selected");
        a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
        x[i].appendChild(a);
        b = document.createElement("DIV");
        b.setAttribute("class", "select-items select-hide");
        for (j = 0; j < selElmnt.length; j++) {
            // Si la valur est vide alors on ne la prend pas en compte
            if (selElmnt.options[j].value === '') {
                continue;
            }
            c = document.createElement("DIV");
            c.innerHTML = selElmnt.options[j].innerHTML;
            if (selElmnt.options[j].selected) {
                c.setAttribute("class", "same-as-selected");
            }
            c.addEventListener("click", function (e) {
                var y, i, k, s, h, l;
                s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                h = this.parentNode.previousSibling;
                l = s.length;
                for (i = 0; i < l; i++) {
                    if (s.options[i].innerHTML == this.innerHTML) {
                        s.selectedIndex = i;
                        // Trigger pour lancer l'evènement
                        $(s).trigger('change');
                        h.innerHTML = this.innerHTML;
                        y = this.parentNode.getElementsByClassName("same-as-selected");
                        for (k = 0; k < y.length; k++) {
                            y[k].removeAttribute("class");
                        }
                        // Pour signaler que c'est cet élément qui doit être sélectionné
                        this.setAttribute("class", "same-as-selected");
                        break;
                    }
                }
            });
            b.appendChild(c);
        }
        x[i].appendChild(b);
        a.addEventListener("click", function (e) {
            e.stopPropagation();
            // Raz de tous les autres select
            closeAllSelect(this);
            // On cache/affiche la liste
            this.nextSibling.classList.toggle("select-hide");
            // On change la direction de la fleche
            this.classList.toggle("select-arrow-active");
        });
    }
}

function updateCustomSelect(select_str) {

    if (select_str === undefined) {
        select_str = '.custom-select';
    }
    var select = $(select_str);

    // Evenement sur le select pour bien le gérer
    $('.select-selected', select).on("click", function (e) {
        e.stopPropagation();
        // Raz de tous les autres select
        closeAllSelect(this);
        // On cache/affiche la liste
        this.nextSibling.classList.toggle("select-hide");
        // On change la direction de la fleche
        this.classList.toggle("select-arrow-active");
    });
    // Maintenant pour chacun de ses fils on va mettre un evenement
    $('.select-items div', select).on('click', function () {
        var y, i, k, s, h, l;
        s = this.parentNode.parentNode.getElementsByTagName("select")[0];
        h = this.parentNode.previousSibling;
        l = s.length;
        for (i = 0; i < l; i++) {
            // En parcourant le select on va conparer le innerhtml du select avec le personalisé pour selectionner le bon element
            if (s.options[i].innerHTML == this.innerHTML) {
                s.selectedIndex = i;
                // Trigger pour lancer l'evènement
                $(s).trigger('change');
                h.innerHTML = this.innerHTML;
                y = this.parentNode.getElementsByClassName("same-as-selected");
                for (k = 0; k < y.length; k++) {
                    y[k].removeAttribute("class");
                }
                // Pour signaler que c'est cet élément qui doit être sélectionné
                this.setAttribute("class", "same-as-selected");
                break;
            }
        }
    });
}

function closeAllSelect(elmnt) {
    var x, y, i, arrNo = [];
    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");
    for (i = 0; i < y.length; i++) {
        if (elmnt == y[i]) {
            arrNo.push(i);
        } else {
            y[i].classList.remove("select-arrow-active");
        }
    }
    for (i = 0; i < x.length; i++) {
        if (arrNo.indexOf(i)) {
            x[i].classList.add("select-hide");
        }
    }
}

document.addEventListener("click", closeAllSelect);
$(document).ready(function () {
    initCustomSelect();
    $(".custom-select select").each(function () {
        var count = $(this).find("option").not(':empty').length;
        if (count == 1) {
            $('.custom-select .select-selected').addClass("removeAfter");
        }
    });
});

function fbs_click(site, params) {

    if (params == '') {
        params = location.href;
    }

    switch (site) {
        case 'fb':
            window.open('http://www.facebook.com/share.php?u=' + encodeURIComponent(params) + '&t=');
            break;
        case 'tw':
            window.open('https://twitter.com/share?url=' + params);
            break;
        case 'insta':
            window.open('https://www.instagram.com/suncooparis/');
            break;
        case 'pin':
            window.open('http://pinterest.com/pin/create/button/?' + params);

            var url = params.split('&');
            url = url[0].split('=');
            url = decodeURIComponent(url[1]);
            break;
    }
    return false;
}

function moveTablePos() {
    var $contentWrapper = $('.order_cgv .wrapper_content_sat.page_text .content_sat'),
        $table = $contentWrapper.find('.wrapperCgvTable'),
        $lastEl = $contentWrapper.find('#row_bloc_4 #bloc_4 strong').last();

    if ($table.length && $lastEl.length) {
        $table.insertBefore($lastEl);
    }
}

/*
open all filters
*/
function filterTrigger(button) {
    if ($('#rayon_sticky_block').length) {
        var trigger = $('#trigger_filtre');
        var filter = $('#rayon_sticky_block');
        var shad = $('#shad_link');

        if (filter.hasClass('show')) {

            if (button === 'reset') {
                $('.margBot .button').next().addClass('loading');
                setTimeout(function () {
                    $('.loader').removeClass('loading');
                }, 2000);
                $("html, body").animate({
                    scrollTop: 0
                }, 200);
            } else {
                $('.forFilters .button').next().addClass('loading');
                setTimeout(function () {
                    $('.loader').removeClass('loading');
                }, 2000);
            }

        }

        if (!trigger.hasClass('triggered')) {
            trigger.addClass('triggered');
            filter.addClass('show');
            shad.addClass('actif filterOn');

        } else if (trigger.hasClass('triggered')) {
            closeFiltre();

        }

        if (trigger.hasClass('triggered')) {
            var shad_filtre = $('#shad_link.filterOn');

            shad_filtre.on("click touch", function () {
                closeFiltre();
            });

        }

        function closeFiltre() {
            if (trigger.hasClass('triggered')) {
                trigger.removeClass('triggered');
                filter.removeClass('show');
                shad.removeClass('actif filterOn');
            }
        }

    }
}

/*
Close filters
*/
function closeFiltre() {
    $(".filter_see").next().addClass("loading");
    $("#rayon_sticky_block").removeClass("show");
    $(".shad").removeClass("actif");
    $("#trigger_filtre").removeClass("triggered");

    setTimeout(function () {
        $(".filter_see").next().removeClass("loading");
    }, 2000);
}

/*
Reset all filters
*/
var tempo_reset = false;
function initAllFilters() {

    $(".filter_remove").next().addClass("loading");

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        ctrl = Array.prototype.slice.call(form.elements, 0);

    ctrl.forEach(function (elm) {
        if ((elm.type === 'checkbox' || elm.type === 'radio')) {
            if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {
                elm.click();

                // Remove is_checked classes
                if ($(elm).hasClass('is_checked')) {
                    $(elm).removeClass('is_checked');
                }
            }
        }
    });

    $('a.tri_price.actif').removeClass('actif');
    $('.menuitem label').removeClass('actif');
    $('input#choice_tri').val(0);

    tempo_reset = true;
    generateNewBlocProd.call(form, 0);

    setTimeout(function () {
        $(".filter_remove").next().removeClass("loading");
    }, 2000);
}

/*
Generate new block product
*/
var bloc_prod_xhr;

function generateNewBlocProd(page, type, idObj, nb_product, btn, fromScrollBottom, fromScrollTop, fromPagination = false) {
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        page = (page !== undefined) ? page : form.getValue('page', parseFloat),
        type = type || form.getValue('type'),
        idObj = idObj || form.getValue('idObj'),
        nb_product = nb_product || form.getValue('nb_prod', parseFloat);
    output = {};

    output = triggerFilter(page, type, idObj, nb_product, 'filter_sticky', fromPagination);

    //Handle pagination with push images
    if (fromPagination) {
        output.page = $('.item:not(.push)').length;
        output.handle_push = 'false';
    }

    //Added for infinite scroll to top
    if (fromScrollTop || fromScrollBottom) {
        $('#is_loading').val('1');
        $('#initial_page').val(page + 1);
        seeAllBtnClick("remove");
    }

    if (page === 'all') {

    } else {
        if (bloc_prod_xhr) {
            bloc_prod_xhr.abort();
        }

        bloc_prod_xhr = $.ajax({
            url: path_relative_root + 'ajax_reload_products.php',
            type: 'get',
            data: output,
            success: function (res) {
                var old_elm = document.getElementById('list_item'),
                    content, itm_length;

                if (btn && btn.nextElementSibling && btn.nextElementSibling.classList.contains('btn_tunnel')) {
                    btn.nextElementSibling.style.display = 'none';
                }

                res = res.split('><><');
                content = res[0].trim();
                itm_length = parseFloat(res[1]);

                let canonical = res[5];
                let prev = res[6];
                let next = res[7];

                $("link[rel^='canonical']").attr("href", path_root + canonical);
                $("link[rel^='alternate'][hreflang^='fr']").attr("href", path_root + canonical);

                $("link[rel^='alternate'][hreflang^='en']").attr("href", JSON.parse(res[8]).en);

                let hrefPrev = $("link[rel^='prev']");
                let hrefNext = $("link[rel^='next']");
                let commentPrev = $('#href_prev');
                let commentNext = $('#href_next');

                changeSeoElement(prev, hrefPrev, commentPrev, 'prev');
                changeSeoElement(next, hrefNext, commentNext, 'next');


                if (content !== '') {
                    if (!isNaN(page)) {
                        old_elm.setAttribute('data-p', (page * nb_product));
                    }

                    if (fromScrollBottom || fromScrollTop) {
                        $(".autoLoad").fadeTo("fast", 0);
                    } else {
                        old_elm.innerHTML = "";
                    }

                    document.getElementById('totalElems').value = itm_length;
                    paginate(page + 1);
                    old_elm.insertAdjacentHTML('beforeend', content);

                    if (window.lazyload !== undefined) {
                        lazyload.init();
                    }

                } else {
                    old_elm.innerHTML = Translator.translate('no_result_txt');
                }
            },
            complete: function () {
                //force trigger on hidden input to fire the change() event
                $('#totalElems').trigger('change');
                $('#bottom_reached').val("0").trigger('change');
                $('#page').trigger('change');

                $('.selected_options').each(function () {
                    var activeFilters = $(this).find('input[type*="checkbox"]:checked').length;
                    var corresondingCounter = $(this).prev('.filter_name').find('.counter');

                    if (activeFilters) {
                        corresondingCounter.html('(' + activeFilters + ')');
                    } else {
                        corresondingCounter.html('');
                    }
                });

                // If we reload the page, we center on the new display
                if (!fromScrollBottom && !fromScrollTop) {
                    if ($('#see_all').val() == 1 && $('.item').length <= nb_product) {

                        /**
                         * When executing infinite scroll (See all) => scroll to the last loaded product
                         */
                        var actualPosition = $(window).scrollTop();
                        window.scrollTo(0,
                            (actualPosition + 100)
                        );

                        // Scroll to the last loaded product based on the value defined in the constant NPPAGE
                        setTimeout(function () {
                            $('html, body').animate({
                                scrollTop: $('#list_item .item:last-of-type').offset().top
                            });
                        });

                    } else {

                        /**
                         * When executing normal pagination (e.g. clicking on page 3), or returning to normal display (e.g. "See X products") => scroll to the top of the page
                         */
                        setTimeout(function () {
                            $("html, body").animate({
                                scrollTop: 0
                            }, 200);
                        }, 250);

                        // Reset initialPage if from filter
                        $("#initial_page").trigger("change");
                    }
                }

                if (fromScrollTop || fromScrollBottom) {
                    $('#is_loading').val('0');
                    seeAllBtnClick("add");
                }

                if (fromScrollBottom) {
                    $("#initial_page").val(page + 1);
                }

                // UPDATE BOTTOM GAUGE WIDTH NB PRODUCTS LOADED / NB PRODUCTS TOTAL + BTN INITIAL STATE (No loader)

                var productLoaded = $('#list_item .item:not(.push)').length;
                var total_element = document.getElementById('totalElems').value;
                var percentLoaded = (productLoaded * 100 / total_element);

                if (productLoaded >= total_element) {
                    $('.nbProdDisplayed').html(Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + productLoaded);
                } else {
                    $('.nbProdDisplayed').html(Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + total_element);
                }
                $('.loadedGauge').html('<span style="width: ' + percentLoaded + '%;"></span>');

                $('.txt_bandeau span').html(total_element + ' ' + Translator.translate('2_product'));

                $('.loader_scroll').removeClass('loading');

                if (productLoaded >= total_element) {
                    $('.loader_scroll').hide();
                } else {
                    $('.loader_scroll').show();
                }

                //Update filter button count
                $('.nbrFilteredProd').html('(' + total_element + ')');

                setTimeout(function () {
                    $('.pagerNav').addClass('appear');
                }, 500);
            }
        });
    }
}

/*
Pagination
*/
function paginate(page) {

    if (document.getElementById('filter_sticky')) {

        var form = document.getElementById('filter_sticky'),
            total_element = form.getValue('totalElems', parseFloat),
            nb_prod = form.getValue('nb_prod', parseFloat),
            nb_total_page = Math.ceil(total_element / nb_prod),
            pagination = $('.pager_wrapper'),
            nb_prod_default = form.getValue('nb_prod_default', parseFloat);
        see_all = form.getValue('see_all', parseFloat);

        page = page || 1;

        $.ajax({
            url: path_relative_root + 'ajax_reload_pagination.php',
            type: 'get',
            data: {
                page: page,
                link: 'paginationGoToPage()',
                nb_total_page: nb_total_page
            },
            success: function (res) {

                var productLoaded = $('#list_item .item:not(".push")').length;
                var percentLoaded = (productLoaded * 100 / total_element);

                pagination.each(function () {

                    if (nb_prod != 1000 && nb_prod < total_element) {
                        this.innerHTML = res + '<div class="nbProdDisplayed">' + Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + total_element + '</div><div class="loadedGauge"><span style="width: ' + percentLoaded + '%;"></span></div><div class="see_all_product"</div>';
                    } else if (total_element > nb_prod_default) {
                        this.innerHTML = '<div class="nbProdDisplayed">' + Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + total_element + '</div><div class="loadedGauge"><span style="width: ' + percentLoaded + '%;"></span></div><div class="see_all_product"</div>';
                    } else {
                        this.innerHTML = '';
                    }
                });
            }
        });
    }
}

/*
Handle pagination to a page
*/
function paginationGoToPage(page, fromScrollBottom, fromScrollTop) {

    page = page || 1;
    var page_bloc = parseInt(page - 1);

    if (fromScrollBottom || fromScrollTop) {
        $(".autoLoad").fadeTo("fast", 1);
    }

    // if we are on search page
    if ($('#search_page').length > 0) {

        generateNewBlocSearch(page_bloc);
    } else {

        generateNewBlocProd(page_bloc, null, null, null, null, fromScrollBottom, fromScrollTop, true);
    }
}

function isInViewport(element) {
    if ($(element).length) {
        var elementTop = $(element).offset().top;
        var elementBottom = elementTop + $(element).outerHeight();
        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();
        return elementBottom > viewportTop && elementTop < viewportBottom;
    }
}

function initItemSlider() {
    if ($('.prodItemSlider').length) {

        $('.prodItemSlider').each(function () {

            id = $(this).parents('.item').attr('id');

            // Disable draggable if swiper is in another swiper
            var draggable = $(this).parents('.swiper-wrapper').length ? false : true;

            if (isInViewport($(this)) && !$(this).hasClass('initialized')) { // Prevent initialized sliders to go back to the first frame during scroll

                new Swiper((this), {
                    roundLengths: true,
                    slidesPerView: 1,
                    lazy: true,
                    preloadImages: false,
                    watchOverflow: true,
                    allowTouchMove: draggable,
                    navigation: {
                        nextEl: $(this).parent().find('.rollover_rayonsNext')[0],
                        prevEl: $(this).parent().find('.rollover_rayonsPrev')[0]
                    },
                });

                $(this).addClass('initialized');
            }
        });
    }
}

/*
Initialize products sliders on search page
*/
function triggerFunctionOnSearchEnd() {
    $('html, body').animate({ scrollTop: 0 }, 'slow'); // Fix the issue of the page not being at the top when the search ends
    initItemSlider(); // init product sliders on search end

    $(window).on('scroll', function () {
        initItemSlider();
    });

    initializeHomeGondoleSwiper(); // init TG sliders on search end
}

/* ------------------------------ ACCOUNT LOGIN ------------------------------ */
// Show lost password form
var afficheLostPassword = function (evt) {
    var signIn = $("#auth");
    var passWd = $("#resetPass");
    var subLogin = $("#subtitleLogin");
    var subPassword = $("#subtitlePassword");

    evt.preventDefault();

    signIn.animate(
        {
            opacity: 0,
        },
        600,
        function () {
            signIn.css("display", "none");

            passWd.css("display", "").animate(
                {
                    opacity: 1,
                },
                600
            );
        }
    );

    subLogin.animate(
        {
            opacity: 0,
        },
        600,
        function () {
            subLogin.css("display", "none");
            subPassword.css("display", "block").animate(
                {
                    opacity: 1,
                },
                600
            );
        }
    );
};

// Reload login form (after you submitted email in lost password form)
var reloadBlocLogin = function (evt) {
    var signIn = $("#auth");
    var passWd = $("#resetPass");
    var subLogin = $("#subtitleLogin");
    var subPassword = $("#subtitlePassword");

    evt.preventDefault();

    passWd.animate(
        {
            opacity: 0,
        },
        600,
        function () {
            passWd.css("display", "none");

            signIn.css("display", "").animate(
                {
                    opacity: 1,
                },
                600
            );
        }
    );

    subPassword.animate(
        {
            opacity: 0,
        },
        600,
        function () {
            subPassword.css("display", "none");

            subLogin.css("display", "").animate(
                {
                    opacity: 1,
                },
                600
            );
        }
    );
};

// Alters item quantity in cart
function changeQte(type) {

    var types = ['plus', 'less'],
        item, loader, qtty, ajax_url;

    if (types.includes(type)) {

        item = this.getValue('item_id', parseFloat);
        loader = document.getElementById('qte_loader_' + item);
        qtty = this.getValue('selected_qte', parseFloat);

        this.qtty_select_wrap.style.display = 'none';
        loader.style.display = 'initial';

        switch (type) {
            case 'plus':
                qtty += 1;
                break;
            case 'less':
                qtty -= 1;
                break;
        }

        $.ajax({
            url: path_relative_root + create_link("order_basket"),
            type: 'post',
            data: {
                action: 'del',
                panierId: item,
                cad: 'non',
                pointcad: 0,
                qteProd: qtty,
                change_qte: true
            },
            cache: false,
            success: function (res) {

                try {
                    res = JSON.parse(res);
                } catch (e) {
                    console.error('JSON ERROR: could not parse data');
                    return false;
                }

                res = $('<div />').html(res.datas);

                document.getElementById('main_cart_wrapper').innerHTML = $('#main_cart_wrapper', res).html();
                if (wShop.$refs.wCouponForm) {
                    wShop.$refs.wCouponForm.reloadBloc += 1;
                }

                //update top cart
                var response = ajax_file("ajax_show_basket.php");
                document.getElementById('cart_top').innerHTML = response;

                cart = Translator.translate('mon_panier');
                var total = $('#cart_top .cart').attr('data-cart'),
                    text;

                if (total <= 1) {
                    text = Translator.translate('one_product');
                } else {
                    text = Translator.translate('2_product');
                }

                $('.cart_main_title.basket').html(cart + ' <span class="nb_products">(' + total + ' ' + text + ')</span>');
                $('#cart_total .nbProdCartTotal').html('<span class="nbProdCartTotal">' + total + ' ' + text + '</span>');

                /**
                 * Move out of stock products to the top of the cart
                 */
                updateCartElements();
            }
        });
    }
}

// Show order list by type
function show_orders_by_type(elementID, type, parentPath, reload) {

    var str_year = '';
    var str_reload = '';
    var year = $("#year").val();

    if (year != '') {
        str_year = '&year=' + year;
    }

    if (reload !== undefined) {
        str_reload = '&reload=true';
    }

    // Handle tab highlight
    var i = 1;
    while (document.getElementById('onglet_' + i + '_mid')) {
        if (type == i) {

            $("#onglet_" + i + "_left").attr('class', 'left-on');
            $("#onglet_" + i + "_mid").attr('class', 'mid-on');
            $("#onglet_" + i + "_right").attr('class', 'right-on');

        }
        else {

            $("#onglet_" + i + "_left").attr('class', 'left-off');
            $("#onglet_" + i + "_mid").attr('class', 'mid-off');
            $("#onglet_" + i + "_right").attr('class', 'right-off');

        }
        i++;
    }

    var container = document.getElementById(elementID);

    if (container) {
        var response = ajax_file(parentPath + 'ajax_account_orders_by_type.php?type=' + type + str_year + str_reload);
        if (response) {
            if (reload !== undefined) {
                $('.content_commandes').html(response);
            } else {

                $(container).html(response);
            }
            return true;
        }
        else {
            container.innerHTML = '';
            return false;
        }
    }
    else
        return false;
}

function showDetailOrder(order_id, order_type) {
    if (show_orders_by_type('orders_by_type', order_type, ROOT_PATH))
        $('#order_' + order_id).click();
    $('html, body').animate({
        scrollTop: ($('[data-btn-name="order_section"]').offset().top - 100)
    }, 500);
}

// Order returns

var global_id_transporteur = '';
function showNumRetourEtiquette(
    idOrder,
    multiTransp,
    parentPath,
    idOrderTransp,
    idTransporteur
) {

    // Define this variable globally to use it in createEtiquetteRetour()
    global_id_transporteur = idTransporteur;

    var divId = 'popup_numero_retour';
    divId += '_' + (multiTransp ? idOrderTransp : idOrder);
    var formId = '#return_products_form';
    formId += '_' + (multiTransp ? idOrderTransp : idOrder);

    openMultiShad(divId);
    $('#shad').addClass('actif');
}

function createEtiquetteRetour(idOrder, multiTransp) {

    var has_errors = false;
    $(".return_quantity").each(function () {
        var return_choice = $(this).closest('.js-select-motif').find('.return_choice');
        return_choice.removeClass('error');

        if ($(this).val() > 0 && return_choice.val() == '0') {
            has_errors = true;
            return_choice.addClass('error');
        }
    });

    if (!has_errors) {

        let data = $('#return_products_form').serialize();
        data += '&idOrder=' + idOrder + '&multiTransp=' + multiTransp;

        $.ajax({
            url: path_relative_root + 'ajax_create_etiquette.php',
            type: 'post',
            data: data,
            success: function (res) {
                location.reload();
            }
        });
    }
}



// Overlay Scrollbar Body
const osInstance = OverlayScrollbars(document.body, {});

/************************** TUNNEL V3 **********************************/
/**********************************************************************/
/*
add product to basket from wishlist (connected)
*/
function moveProductFromWishlistToCart(elemBtn) {

    $(elemBtn).addClass('processing');
    var prodId = $(elemBtn).attr("data-produit-id");
    var colorId = $(elemBtn).attr("data-color-id");
    var sizeId = $(elemBtn).attr("data-size-id");

    let datas = {
        data: $(elemBtn).attr("data-id"),
    };

    if (prodId !== undefined) {
        datas.productId = prodId;
    }

    if (colorId !== undefined) {
        datas.colorId = colorId;
    }

    if (sizeId !== undefined) {
        datas.sizeId = sizeId;
    }

    $.post(path_relative_root + "ajax_add_panier_wishlist.php", datas, function (resp) {
        if (resp === "ok") {
            document.location.href = path_relative_root + create_link('order_basket');
        }
    });
}

/*
Opens the size selection for modification in the cart.
*/
function toggleModifySize() {
    $('.toggle_modal_actions').on('click', function () {

        var $cartItemActions = $(this).parents('.product_line_basket').find('.cart_item_actions');
        var $modifySize = $(this).parents('.product_line_basket').find('.toggle_modal_actions');

        $modifySize.hide();
        $cartItemActions.slideDown(300);

        $cartItemActions.find('input').each(function () {
            var $thisInput = $(this);
            var $closestFormItem = $thisInput.closest('.form_itm');

            if ($thisInput.is(':checked')) {
                $closestFormItem.addClass('selected');
            }

            if ($thisInput.is(':disabled')) {
                $closestFormItem.addClass('disabled');
            }
        });
    });

    $('.form_itm.size').click(function () {
        $('.form_itm.size').removeClass('selected');
        $(this).addClass('selected');
    });
}

/*
Cart size information lightbox processing order button
*/
function mySubmit() {
    $(".cartLoader").addClass("loading");
    var form = document.getElementById("formPanier");
    var button = document.querySelector(".cartSizeInfoBtn");

    if (!button) {
        return;
    }

    var nextElement = button.nextElementSibling;
    form.action = create_link('order_basket');

    if (nextElement) {
        nextElement.classList.add("loading");
    }

    setTimeout(function () {
        form.submit();
    });
}

/**
Toggle product details in cart right column
*/
function toggleListProduct() {
    $(".total_produit.toggle").on("click", function () {
        $(this).toggleClass("open");
        $(".total_produit_dropdown").slideToggle();
    });
}

// Get product data
function getProductDetail(productId) {
    let form = (this instanceof HTMLFormElement) ? this : document.getElementById('prod_info_' + productId);
    let productColor = form.getValue('idCouleur');
    let val_color = form.getValue(productColor);
    let productSize = form.getValue('idTaille');
    let val_size = form.getValue(productSize);
    let data = {
        produitId: productId,
        colorId: val_color,
        sizeId: val_size,
        json: 'json'
    };

    $.ajax({
        url: path_relative_root + 'ajax_product_data.php',
        type: 'get',
        async: false, // Should be async but would then need a loader so...
        dataType: 'json',
        data: data,
        success: function (res) {

            // Set event ID for product detail dataLayer
            if (typeof (res) == 'object') {
                pushIntoDatalayer(res.dataLayerData.productDetails);
            }
        }
    });
}

//Display lightbox contact store
function showLightboxContactStore() {
    var shad = document.getElementById('shad');
    var modbox = document.getElementById('pdvBox');

    $('html, body, .os-viewport').animate({
        scrollTop: 0
    }, 500);

    $(modbox).addClass('actif');
    $('#ligthbox_send_store_information').addClass('actif').show();
    $(shad).addClass('actif');
    $(shad).on('click', closeLightboxContactStore);
    $('#content_pdvBox').show();

    setTimeout(function () {
        $('#numTelephone').intlTelInput();
    }, 400);
}

// Close lightbox contact store
function closeLightboxContactStore() {
    var shad = document.getElementById('shad');
    var modbox = document.getElementById('pdvBox');

    $(modbox).removeClass('actif');
    $(shad).removeClass('actif');
    $('#ligthbox_send_store_information').removeClass('actif');
}

function hide_lightbox_send_info() {
    closeMultiShad('ligthbox_send_store_information');
    $('#shad').removeClass('actif');
}

// function close_search_form() {
//     $('.wrap_search_field').removeClass('actif');
//     $('#shad').removeClass('actif for_search');
// }

// Function Zoom
function zoomImg(slide) {
    window.setTimeout(function () {
        // Check if more than one swiper-slide for swiper to activate
        if ($('#zoom_box_wrapper .swiper-slide').length > 1) {
            // Show btn if swiper is activated
            $('.zoomProdNext, .zoomProdPrev').show();
            var productLightboxVisu = new Swiper('#zoom_box_wrapper', {
                navigation: {
                    nextEl: '.zoomProdPrev',
                    prevEl: '.zoomProdNext'
                }
            });

            // Upadte slide depending of what image has been clicked
            productLightboxVisu.slideTo(slide - 1, false, false);
            // Update Swiper so as it knows the swiper was closed
            productLightboxVisu.update();

        } else {
            // Hide btn if only 1 swiper-slide
            $('.zoomProdNext, .zoomProdPrev').hide();
        }
    }, 50);

    // Disable zoom on image on lookbook
    if ($('.page_rayon.rayon_lookbook').length && $('#lightbox_achat_express').length) {
        return;
    }

    if (!$('#zoom_box_wrapper.open').length) {
        openMultiShad('zoomBox');

    } else {
        closeMultiShad('zoomBox');
    }
}

//Lookbook product preview
function ajax_achat_express(reference, productId, productUrl) {

    $.ajax({
        type: 'get',
        url: path_relative_root + 'ajax_achat_express.php?ref=' + reference,
        success: function (response) {
            let lightbox = $('#lightbox_achat_express');

            if (!lightbox.length) {
                $('#site_global_wrap').append('<div id="lightbox_achat_express" class="lightbox"></div>');
                lightbox = $('#lightbox_achat_express');
            }

            lightbox
                .html('<div class="express_response"><button class="close_pop" onclick="closeMultiShad(\'lightbox_achat_express\')">' + '<span>X</span>' + '</button>' + response + '</div>')
                .appendTo('#site_global_wrap')
                ;
            openMultiShad('lightbox_achat_express');
            showQteListe('liste_qte_range', productId, path_relative_root);

            // Add event on Wishlist button
            $('#addToWishlistButton').on('click', addToWishlist);

            // For the tooltip events "Please select a size"
            eventSizeButton();

            // Add "Go to product card" button
            addGoToProductCardButton(productUrl);

            // For tooltips events "Please choose a size"
            eventButton();

            setTimeout(function () {
                checkSubmitState();
            }, 500);

            if ($('#lightbox_achat_express').length) {
                if ($(".mainSlideFp .swiper-slide").length > 1) {
                    setTimeout(() => {
                        mainSlideFp = new Swiper(".mainSlideFp", {
                            sliderPerView: 1,
                        });
                    }, 300);
                }
            }

            // By default show 'sélectionner une taille' btn if no size is chosen
            noSizeSelected();
        }
    });
}

/*
Tooltips events "Please choose a size"
*/
function eventSizeButton() {
    var disabledBtnTooltip = $(".disabledBtnTooltip");

    disabledBtnTooltip.find(".disabledBtnInfos").text(Translator.translate('choose_size_fp_disabled_btn'));
}

/*
Add product page redirection button
*/
function addGoToProductCardButton(productUrl, appendTo = '.product_info_wrapper') {
    let go_to_fp = $('<div>', { class: 'go_to_fp' });
    let go_to_fp_trans = translate('go_to_fp');
    let a_href = $('<a>', { title: go_to_fp_trans, text: go_to_fp_trans, href: path_relative_root + productUrl });

    a_href.appendTo(go_to_fp);
    go_to_fp.appendTo(appendTo);
}

// FP Description Accordion
function fpAccordion() {
    $(".toggleCarac").each(function () {
        $(this).click(function () {
            if ($(this).hasClass('actif')) {
                $(this).removeClass('actif');
                $(this).next().slideUp();
            } else {
                $(".toggleCarac").removeClass('actif');
                $(".toggleCarac + .content").slideUp();
                $(this).next().slideUp();
                $(this).addClass('actif');
                $(this).next().slideDown();
            }
        });
    });
}

// Handle opening and closing of messages in account

function openCloseMessage(elem, msg_open, msg_close) {
    var id = elem.attr('id'),
        mId = id.substring(id.indexOf('_') + 1),
        messageContainer = $('.message_account_container');

    if (messageContainer.hasClass('appear')) {
        messageContainer.removeClass('appear');
    } else {
        messageContainer.addClass('appear');
    }

    if (!(elem).hasClass('actif')) {
        $('.message_detail').slideUp();
        $('.btn_ouvrir_message').not(elem).removeClass('actif');
        $('.btn_ouvrir_message').not(elem).text(msg_open);
        // If message container is empty, or has the reply form as its only child
        if (($('#msg_' + mId).html() == '') || ($('#msg_' + mId + ' form').length && ($('#msg_' + mId).children().length == 1))) {
            $.ajax({
                type: 'post',
                data: { mId: mId, type: 1 },
                url: path_relative_root + create_link('message_detail'),
                success: function (response) {
                    $('#msg_' + mId).prepend(response);
                    $('#msg_' + mId).slideDown();
                    (elem).text(msg_close);
                }
            });
        }
        else
            $('#msg_' + mId).slideDown();
        (elem).addClass('actif');
        (elem).text(msg_close);
    } else {
        $('.message_detail').slideUp();
        (elem).removeClass('actif');
        (elem).text(msg_open);
    }
}
function show_message(mId, hide) {

    if (hide == 0) {
        var ajax_content = ajax_file('ajax_show_messages.php?mId=' + mId);
        if (ajax_content) {

            document.getElementById("message_" + mId).innerHTML = ajax_content;

            document.getElementById("message_" + mId).onclick = '';
            document.getElementById("message_" + mId).style.cursor = 'default';

        }
    } else {
        var ajax_content = ajax_file('ajax_show_messages.php?mId=' + mId + '&hide=1');
        if (ajax_content) {

            document.getElementById("message_" + mId).innerHTML = ajax_content;

            document.getElementById("message_" + mId).onclick = "show_message(" + mId + ",0);";

            document.getElementById("message_" + mId).style.cursor = 'pointer';

        }
    }
}

/*
Faq accordion in cart
**/
function fadePanier(id, nbelem) {
    for (var i = 1; i <= nbelem; i++) {
        if (id != i && $("#texte_question_" + i).css('display') == 'block') {
            $("#texte_question_" + i).slideUp(400);
            $("#intitule_question_" + i).removeClass('actif');
        }
    }

    var $texteQuestion = $("#texte_question_" + id);
    var $intituleQuestion = $("#intitule_question_" + id);

    if ($texteQuestion.text().trim() === "") {
        // Prevent slideDown when the question text is empty
        return;
    } else if ($texteQuestion.css('display') != 'block') {
        $texteQuestion.slideDown(400).addClass('actif');
        $intituleQuestion.addClass('actif');
    } else {
        $texteQuestion.slideUp(400).removeClass('actif');
        $intituleQuestion.removeClass('actif');
    }
}

/*
Cookies consent
**/
function changeCookiesConsentDisplay() {
    if ($('#cookiesLightbox').length) {
        var wrapper = document.createElement("div");
        wrapper.className = "cookiesLightbox_actions_wrapper";

        var containerLeft = document.createElement("div");
        containerLeft.className = "cookiesLightbox_actions_left";

        var containerRight = document.createElement("div");
        containerRight.className = "cookiesLightbox_actions_right";

        var refuseBtnWrapper = document.querySelector(".cookiesLightbox_refuse_btn");
        var customBtnWrapper = document.querySelector(".cookiesLightbox_custom_btn");
        var acceptBtnWrapper = document.querySelector(".cookiesLightbox_accept_btn");

        wrapper.appendChild(containerRight);
        wrapper.appendChild(containerLeft);

        containerLeft.appendChild(customBtnWrapper);
        containerLeft.appendChild(refuseBtnWrapper);
        containerRight.appendChild(acceptBtnWrapper);

        var cookiesLightboxContainer = document.querySelector(".cookiesLightbox_container");
        var preferencesElement = document.querySelector(".cookiesLightbox_preferences");

        cookiesLightboxContainer.insertBefore(wrapper, preferencesElement);
    }
}

/*
Enable menu shad
**/
function enableMenuShad() {
    const menu = document.querySelector('.headerTop .mainMenu');
    const menuEntries = document.querySelectorAll('.menu_wrapper');
    const shad = document.getElementById('shad_menu');

    if (menu && menuEntries.length && shad) {
        menuEntries.forEach(menuEntry => {
            menuEntry.addEventListener('mouseenter', function () {
                if (!shad.classList.contains('for_menu')) {
                    shad.classList.add('actif');
                }
            });

            menuEntry.addEventListener('mouseleave', function () {
                if (!shad.classList.contains('for_menu')) {
                    shad.classList.remove('actif');
                }
            });
        });
    }
}

// Add specific offered product to cart
function add_produit_specifique() {
    let form = document.querySelector('#lightbox_achat_express .express_response form');
    let product_id = form.getValue('produit_id');
    let color_id = form.getValue('couleurProd');
    let size_id = form.getValue('tailleProd');
    let retour = ajax_file(path_relative_root + 'ajax_add_produit_specifique.php?product_id=' + product_id + '&color_id=' + color_id + '&size_id=' + size_id);

    if (retour) {
        location.href = path_relative_root + create_link('order_basket');
    }
}

function checkStickyMenu() {
    //initial value = flex gap in sticky header + logo width
    var elementsWidth = 200;
    elementsWidth += $('.switchLangBtn').width() + $('.block_top_links.menu').width() + $('.block_top_links.rightSide').width();
    //if the header contents are too wide to fit in the header, enable burger menu
    if (elementsWidth > $('#site_head_wrap').width() ) {
        $('#site_head_wrap').addClass('overflowing');

        if(window.scrollY <= 0) {
            $('#site_head_wrap').removeClass('overflowing stickyburger');
            $('#burgerMenu').removeClass('open');
        }
    } else {
        $('#site_head_wrap').removeClass('overflowing stickyburger');
        $('#burgerMenu').removeClass('open');
    }
}

function toggleBurgerMenu() {
    $('#burgerMenu').toggleClass('open');
    $('#site_head_wrap').toggleClass('stickyburger');
}
