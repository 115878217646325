/* ------------------------------ STORE LOCATOR ------------------------------ */
// Creation d'un bloc correspondant a un marqueur sur la liste laterale
function creerLigneListe(
    cpt,
    id,
    nom,
    adresse,
    ville,
    cp,
    pays,
    currently_open,
    horaire_today,
    distance,
    marqueur,
    picture_filtre,
    magasinId,
    stock,
    stockStatus,
    stock_quantity,
    clientId,
    favorite,
    beContacted,
    flag_available,
    url,
    shop_filter,
    telephone,
    query,
    lat,
    lng
) {
    currently_open = parseFloat(currently_open);
    var li = document.createElement("li");
    li.id = id;
    li.dataset.lng = lng;
    li.dataset.lat = lat;
    li.className = "elem-list-store";

    if (lat != "" && lng != "" && !$("body.product_page").length) {
        li.setAttribute("onclick", "geolocGoto('" + lat + "', '" + lng + "')");
    }

    if (favorite == "1") {
        choose_fav = Translator.translate("actual_favorite_store");
    } else {
        choose_fav = Translator.translate("choose_as_favorite_store");
    }

    /***** Product stock infos *****/
    if (stock_quantity == "undefined" || stock_quantity == "") {
        stock_status_msg = "unknow_stock";

    } else {
        var in_stock = stock_quantity >= 6 && stock_quantity <= 9999;
        var limited_stock = stock_quantity >= 1 && stock_quantity <= 5;
        var not_in_stock = stock_quantity == 0;
        var to_be_confirmed_stock = stock_quantity == 10000;
        var can_be_ordered_stock = stock_quantity == 11000;
        var stock_status_msg = "";
        
        if (in_stock) {
            stock_status_msg = "in_stock";
        }
        if (limited_stock) {
            stock_status_msg = "limited_stock";
        }
        if (not_in_stock) {
            stock_status_msg = "not_in_stock";
        }
        if (to_be_confirmed_stock) {
            stock_status_msg = "to_be_confirmed_stock";
        }
        if (can_be_ordered_stock) {
            stock_status_msg = "can_be_ordered_stock";
        }
    }

    var info_horaire_today = !horaire_today
        ? Translator.translate("store_closed")
        : horaire_today;
    var class_horaire_today = currently_open ? "store_open" : "store_closed";
    var ligneListe =
        '<div class="elem_list_contents">' + '<div class="nom_store">';
    if (distance) {
        ligneListe +=
            '<h2 class="title">' +
            nom +
            '<p class="distance">' +
            "(" +
            distance +
            "km)" +
            "</p></h2>";
    } else {
        ligneListe += '<h2 class="title">' + nom + "</h2>";
    }
    ligneListe += "</div>" + '<div class="content_store">';

    if ($("body.product_page").length) {
        ligneListe += `<div class="detail_store" onclick="location.href = '${
            path_relative_root + url
        }'">`;
    } else {
        ligneListe += '<div class="detail_store">';
    }
    ligneListe +=
        '<div class="col-1">' +
        '<span class="rue_store">' +
        adresse +
        "</span>" + " - " + cp +
        " " +
        ville +
        '<span class="pays_store">' +  " - " +
        pays +
        "</span>" +
        "</span>" +
        '<span class="tel_store">' +
        telephone +
        "</span></div>";

    var is_page_product = $("body.product_page").length == 1;

    if (
        (info_horaire_today !== "" && currently_open) ||
        (!currently_open && horaire_today)
    ) {
        ligneListe +=
            '<div class="col-2"><p class="horaire_today puce_' +
            class_horaire_today +
            '">' +
            info_horaire_today +
            "</p></div>";
    }

    isFavorite = favorite ? "favorite_store_js" : "";
    ligneListe +=
        '<a href="javascript:;" class="addfavorite" id="store' +
        magasinId +
        '" onclick="addToMyFavorite(\'' +
        magasinId +
        "', '" +
        clientId +
        "', '" +
        favorite +
        "', '" +
        escape(info_horaire_today) +
        "','" +
        currently_open +
        "','" +
        escape(horaire_today) +
        "','" +
        class_horaire_today +
        "','" +
        stock +
        "','" +
        stockStatus +
        "'); return false;\">";
    ligneListe +=
        '<span class="choose_fav_label" id="favShop-' +
        magasinId +
        '"><span class="' +
        isFavorite +
        '">' +
        choose_fav +
        "</span></span></a>";

    ligneListe += "</div>";
    if (document.getElementById("produit_id")) {
        /***** Product stock infos msg *****/
        if (stock != "undefined") {
            ligneListe +=
                '<p class="stock_status ' +
                stock_status_msg +
                '">' +
                Translator.translate(stock_status_msg + "_msg") +
                "</p>";
        }
    }
    if (is_page_product) {
        if (beContacted == 1) {
            if ($("body.product_page.mobile").length) {
                ligneListe +=
                    '<div class="availableTxt store_more" onclick="handleChangeMobile(' +
                    id +
                    ')">' +
                    "<span>" +
                    txt_available_mag +
                    "</span>" +
                    "</div>" +
                    "</div>" +
                    "</div>";
            } else {
                var nom_mag = nom.replace(/'/g, "\\'");
                ligneListe +=
                    '<div class="availableTxt store_more" onclick="handleChange(\'' +
                    id +
                    "','" +
                    nom_mag +
                    "')\">" +
                    "<span>" +
                    txt_available_mag +
                    "</span>" +
                    "</div>" +
                    "</div>" +
                    "</div>";
            }
        } else {
            ligneListe += "</div>" + "</div>";
        }
    }

    if (!is_page_product) {
        ligneListe += '<div class="store_more"><a onclick="triggerStoreDatalayerPush(' + id + ')" href="' + path_relative_root + url + '">' + Translator.translate('afficher_horaires') + '</a>'
            + '</div>';
    }
    ligneListe += '<a onclick="createDataLayer(' + id + ')" href="https://maps.google.fr/maps?daddr=' + lat + ',' +  lng + '" class="button secondary btn_to_store" target="_blank">' + Translator.translate('store_route') + '</a>'

    ligneListe += "</div>" + "</div>" + "</div>";

    li.innerHTML = ligneListe;

    li.addEventListener("click", function () {
        google.maps.event.trigger(marqueur, "click");
    });

    return li;
}

function creerMarqueur(
    point,
    nom,
    adresse,
    ville,
    cp,
    pays,
    horaire,
    telephone,
    email,
    site,
    image_mag,
    type_mag,
    cpt,
    magasin_id,
    url,
    distance,
    horaire_today,
    currently_open
) {
    var img = path_relative_root + "img/maps/pin.svg";
    var http = new RegExp("^http://", "g");

    // Origins, anchor positions and coordinates of the marker
    // increase in the X direction to the right and in
    // the Y direction down.
    var image = new google.maps.MarkerImage(
        img,
        // This marker is 17 pixels wide by 25 pixels tall.
        new google.maps.Size(17, 25),
        // The origin for this image is 0,0.
        new google.maps.Point(0, 0)
    );

    // The anchor for this image is the base of the flagpole at 0,32.
    //new google.maps.Point(10, 26));
    // Shapes define the clickable region of the icon.
    // The type defines an HTML <area> element 'poly' which
    // traces out a polygon as a series of X,Y points. The final
    // coordinate closes the poly by connecting to the first
    // coordinate.
    var marqueur = new google.maps.Marker({
        position: point,
        icon: image,
    });

    marqueur.set("magasin_id", magasin_id);
    nom = $.trim(nom);
    adresse = $.trim(adresse);
    ville = $.trim(ville);
    cp = $.trim(cp);
    pays = $.trim(pays);
    horaire = $.trim(horaire);
    telephone = $.trim(telephone);
    email = $.trim(email);
    site = $.trim(site);
    distance = Math.round(distance);
    var info_horaire_today = !horaire_today ? "" : horaire_today;
    var class_horaire_today = currently_open ? "store_open" : "store_close";
    var currently_open_info = currently_open
        ? Translator.translate("store_opened")
        : Translator.translate("store_closed");

    if (distance) {
        var ligneListe =
            '<div class="info_popup"><div class="name"><a href="' +
            url +
            '">' +
            nom +
            '</a><span class="dist">(' +
            distance +
            " km)</span></div>";
    } else {
        var ligneListe =
            '<div class="info_popup"><div class="name"><a href="' +
            url +
            '">' +
            nom +
            "</a></div>";
    }

    ligneListe +=
        '<div class="adresse">' +
        adresse +
        " - " +
        cp +
        " " +
        ville +
        "<br />" +
        pays +
        "</div>";

    if (telephone != "")
        ligneListe += '<div class="coord">' + telephone + "</div>";

    if (site != "")
        if (!http.test(site)) {
            ligneListe +=
                '<a href="http://' +
                site +
                '" class="site" target="_blank">' +
                site +
                "</a>";
        } else {
            ligneListe +=
                '<a href="' +
                site +
                '" class="site" target="_blank">' +
                site +
                "</a>";
        }

    if (horaire != "")
        if (class_horaire_today == "store_open") {
            ligneListe +=
                '<div class="horaire_title"><span class = "' +
                class_horaire_today +
                '">' +
                currently_open_info +
                "</span>" +
                info_horaire_today +
                '</div><div class="horaire_info">' +
                horaire +
                "</div>";
        } else {
            //If closed we don't show "info_horaire_today" since it displays "Fermer Fermé"
            ligneListe +=
                '<div class="horaire_title"><span class = "' +
                class_horaire_today +
                '">' +
                currently_open_info +
                '</span></div><div class="horaire_info">' +
                horaire +
                "</div>";
        }
    
    // Tooltip more infos & go to store link
    ligneListe += `<div class="store_localisation_container">
        <div class="btn_voir_magasin go_to_store"><a class="geolocalisation_link" target="_blank" href="https://maps.google.fr/maps?daddr=${point.lat()},${point.lng()}">${Translator.translate(
        "go_to_store"
    )}</a></div>
        <div class="btn_voir_magasin"><a href="${url}">${Translator.translate(
        "voir_magasin"
    )}</a></div>
        </div>`;

    google.maps.event.addListener(marqueur, "click", function () {
        // selected store coordinates
        var storePosition = new google.maps.LatLng(point.lat(), point.lng());
        // center map on marker
        maCarte.setCenter(storePosition);
        // map animation on marker switch
        maCarte.panTo(storePosition);

        if (!infowindow) {
            infowindow = new google.maps.InfoWindow();
            infowindow.setContent(ligneListe);
            infowindow.open(maCarte, marqueur);
        } else {
            infowindow.setContent(ligneListe);
            infowindow.open(maCarte, marqueur);
        }
    });

    var detail_store_panel = document.getElementById("store_id_dataLayer");
    detail_store_panel.textContent = magasin_id;
    markersArray.push(marqueur);

    return marqueur;
}