/*
    JS file structure to follow:
    - Global variables
    - Initialization
    - Events
    - Callback functions
    - General functions

    IMPORTANT! : Initializations and events are to be placed in the $(function() { ... });
    IMPORTANT! : Callback functions and general functions are to be placed outside of the $(function() { ... });

    Don't forget to comment the code in english for better understanding and maintainability of the code. And if possible use explicit variables and function names.
*/

alert = (function () {

    "use strict";

    var shade, albox, alert_tmpl;
    var regex = new RegExp("\\s", "g");

    function init() {

        shade = document.getElementById("shad_abox");
        albox = document.getElementById("abox");

        alert_tmpl = albox.innerHTML.trim();

        window.removeEventListener("load", init);
    }

    function closeAlertBox() {

        $(albox).fadeOut(400, function() {
            $('#abox .form_submit').css('display', 'flex');
        });
        $(shade).off("click", closeAlertBox).fadeOut(600);
        $('body').removeClass('alert_open');
    }

    function alert(str) {

        $('body').addClass('alert_open');

        $('.txt_alert', albox).html(str);

        $(".close", albox).on("click", closeAlertBox);
        $(shade).one("click", closeAlertBox);

        window.scroll(0,0);

        $(shade).css('display', 'block');
        $(shade).animate({
            opacity: 0.7
        },200);
        $(albox).fadeIn(400);
    }

    window.addEventListener("load", init);

    return alert;
}());